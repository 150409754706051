import Afeefa from '../../../../assets/images/team_members/Afeefa.png'
import Drew from '../../../../assets/images/team_members/Drew.png'
import Evan from '../../../../assets/images/team_members/Evan.png'
import Katie from '../../../../assets/images/team_members/Katie.png'
import Laura from '../../../../assets/images/team_members/Laura.png'
import Loren from '../../../../assets/images/team_members/Loren.png'
import Matthew from '../../../../assets/images/team_members/Matthew.png'
import Richard from '../../../../assets/images/team_members/Richard.png'
import Rodney from '../../../../assets/images/team_members/Rodney.png'
import Usman from '../../../../assets/images/team_members/Usman.png'
import Skinner from '../../../../assets/images/team_members/Skinner.png'

export const teamMembers = [
  {
    name: 'Katie Fortunato',
    designation: 'President & Co-founder',
    linkedinUrl: 'https://www.linkedin.com/in/katieclark1',
    imgSrc: Katie,
  },
  {
    name: 'Loren Heinbach',
    designation: 'VP of Operations',
    linkedinUrl: 'https://www.linkedin.com/in/lorenheinbach',
    imgSrc: Loren,
  },
  {
    name: 'Matthew Braccio',
    designation: 'Director of Business Development',
    linkedinUrl: 'https://www.linkedin.com/in/mbraccio',
    imgSrc: Matthew,
  },
  {
    name: 'Richard Skinner',
    designation: 'Head of Product',
    linkedinUrl: 'https://www.linkedin.com/in/richard-skinner-product/',
    imgSrc: Skinner,
  },
  {
    name: 'Drew Lord',
    designation: 'Director of Strategic Initiatives',
    linkedinUrl: 'https://www.linkedin.com/in/drewblord',
    imgSrc: Drew,
  },
  {
    name: 'Rodney Hess',
    designation: 'Director of Content',
    linkedinUrl: 'https://www.linkedin.com/in/hesseo',
    imgSrc: Rodney,
  },
  {
    name: 'Laura Walker Smith',
    designation: 'Client Success Director',
    linkedinUrl: 'https://www.linkedin.com/in/laura-walker-smith',
    imgSrc: Laura,
  },
  {
    name: 'Richard Gregory',
    designation: 'Engineering Manager',
    linkedinUrl: 'https://www.linkedin.com/in/richard-gregory-a3228921b/',
    imgSrc: Richard,
  },
  {
    name: 'Afeefa Zaman',
    designation: 'Full-Stack Developer',
    linkedinUrl: 'https://www.linkedin.com/in/afeefazaman',
    imgSrc: Afeefa,
  },
  {
    name: 'Evan Rose',
    designation: 'LAMP Developer',
    linkedinUrl: 'https://www.linkedin.com/in/evanmrose',
    imgSrc: Evan,
  },
  {
    name: 'Usman Ahmed',
    designation: 'Full-Stack Developer',
    linkedinUrl: 'https://www.linkedin.com/in/hatx/',
    imgSrc: Usman,
  },
]
