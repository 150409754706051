import HeroImageComponent from '@/components/atoms/solutionProfile/HeroImageComponent'
import EmptySection from '@/components/atoms/widgets/EmptySection'
import { ChangeEvent, RefObject } from 'react'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { LiaTrashAltSolid } from 'react-icons/lia'
import { deleteRequest } from '@/utils/axiosRequests'
import { confirmDialogue } from '@/utils/confirmDialogue'
import { useToken } from '@/components/atoms/forms/Form'
import { toast } from 'react-toastify'
import { router } from '@inertiajs/react'
import { defaultToastOptions } from '@/utils/commonToast'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import { TIMEOUT_INTERVAL } from '@/utils/constants'

type HeroImageSectionProps = {
  solution: schema.Solution;
  current_user?: schema.User;
  is_claimed: boolean;
  solutionOwner: schema.User;
  heroImageURL?: string;
  heroPreviewUrl?: string;
  heroInputRef: RefObject<HTMLInputElement>;
  handleHeroButtonClick: () => void;
  handleHeroImageChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  isRmcAdmin: boolean;
}

const HeroImageSection = ({
  solution,
  is_claimed,
  current_user,
  solutionOwner,
  heroPreviewUrl,
  heroImageURL,
  handleHeroButtonClick,
  handleHeroImageChange,
  heroInputRef,
  isRmcAdmin
}: HeroImageSectionProps) => {

  const heroImage = heroPreviewUrl || heroImageURL
  const token = useToken()

  const handleDeleteHeroImage = async () => {
    try {
      if (!confirmDialogue('Are you sure you want to delete this image?')) return
      const response = await deleteRequest(`/solutions/${solution.id}/delete_hero_image`, token)

      const message = response.data.message

      if (message === 'Success') {
        toast(
          <SuccessMessage message={'Hero Image has been deleted'} />,
          defaultToastOptions('teal-tint-2')
        )
        setTimeout(() => router.visit(`/solutions/${solution.slug}`), TIMEOUT_INTERVAL)
      } else if (message === 'Not Found') {
        toast(
          <ErrorMessage message={'Hero Image not Found'} />,
          defaultToastOptions('red-200')
        )
      }
    } catch (error) {
      toast(
        <ErrorMessage />,
        defaultToastOptions('red-200')
      )
    }
  }

  return (
    (solution.paid_solution && (is_claimed || isRmcAdmin)) && (
      <>
        {(current_user && current_user?.id === solutionOwner?.id) || isRmcAdmin ? (
          <div>
            <div className='absolute right-0 mt-[10px] mr-[10px] rounded-full bg-white p-2 flex flex-col gap-2'>
              <MdOutlineModeEditOutline className='text-[20px] text-dark-blue cursor-pointer' onClick={handleHeroButtonClick} />
              {heroImage && <LiaTrashAltSolid className='text-[20px] text-dark-blue cursor-pointer' onClick={handleDeleteHeroImage} />}
              <input
                id='hero-image-input'
                type='file'
                accept='.jpg,.png,.jpeg'
                onChange={(event) => handleHeroImageChange(event)}
                ref={heroInputRef}
                className='hidden'
              />
            </div>

            {heroImage ? (
              <HeroImageComponent image={heroImage} />
            ) : (
              <EmptySection heading='No Hero Image Yet' paragraph='Click the pencil icon to add a Hero Image to this solution.' />
            )}
          </div>
        ) : (
          heroImageURL && (
            <HeroImageComponent image={heroImage} />
          )
        )}
      </>
    )
  );
};

export default HeroImageSection;
