import React from 'react'

export default function ArticleCard({ arrow, image, authorImage, authorName, title, category, date, duration }) {

  return (
    <div className={`mb-2 content rounded-2xl shadow-md  max-w-[156px] min-h-[217px] sm:!min-w-[200px] sm:!min-h-[278px] md:!min-w-[230px] md:!min-h-[319px] lg:!min-w-[304px] lg:!min-h-[421px] mx-[4px] sm:mx-[6px] md:mx-[10px] lg:mx-[15px]`}>
      <div className={'rounded-t-2xl h-[142px] sm:!h-[160px] md:!h-[180px] lg:!h-[276px] bg-cover bg-center'} style={{ backgroundImage: `url(${image})` }}>
        <div className='flex items-center justify-between px-2 sm:px-4 py-1'>
          <div className='flex items-center'>
            <img src={authorImage} alt={`Author`} className='sm:mt-1 p-0 rounded-full size-[0.938rem] sm:size-[1.642rem]' />
            <p className='ml-2 text-black text-[0.321rem] md:text-[0.625rem] font-semibold leading-normal'>By {authorName}</p>
          </div>
          <img src={arrow} alt={`Arrow Icon`} className='ml-2 mt-1 p-0 hidden sm:block' />
        </div>
      </div>
      <div className='flex flex-col justify-evenly px-3 sm:p-5 sm:gap-0 h-[75px] sm:!h-[118px] md:!h-[139px] lg:!h-[145px]'>
        <div className='text-[0.321rem] sm:text-[0.625rem] text-gray-500 font-semibold leading-normal'>
          <p>{category}</p>
        </div>
        <p className='h-[40px] sm:!h-[80px] md:![100px] lg:![104px] overflow-hidden text-black text-[0.593rem] md:text-[12px] lg:text-[15px] font-bold leading-normal'>{title}</p>
        <div>
        <hr className='w-[134px] sm:w-auto mb-1 sm:mb-2 ' />
        <p className='text-[0.321rem] text-black sm:text-[0.625rem] font-semibold leading-normal'>{date} - {duration === "< 1" ? 'Less than 1 minute' : duration + ' minutes'}</p>
        </div>
      </div>
    </div>
  )
}
