import { useState } from 'react'

import { Tooltip } from '../widgets/TooltipBody'

import { SolutionStarRating } from '@/components/molecules/solution/SolutionCard'

export const Ratings = ({ solution }) => {
  const [ratingsTooltipVisible, setRatingsTooltipVisible] = useState(false)

  return (
    <div className='relative flex'>
      <button
        className='relative text-left'
        onClick={() => setRatingsTooltipVisible(!ratingsTooltipVisible)}
      >
        <SolutionStarRating solution={solution} showDetails={true}/>
      </button>
      {ratingsTooltipVisible && (
        <Tooltip>
          <div>
            Our ratings are determined by an average of publicly available data.
          </div>
          <button
            className='mt-4 underline'
            onClick={() => setRatingsTooltipVisible(!ratingsTooltipVisible)}
          >
            Got It
          </button>
        </Tooltip>
      )}
    </div>
  )
}
