import window from 'global'
import { useEffect, useState, lazy, Suspense } from 'react'
import { redirectToUrl } from '@/utils/helperFunctions';
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal';
import useCurrentUser from '@/hooks/useCurrentUser';

const HighlightsCard = ({ mobile, desktop, href, cardName }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false)
  const currentUser = useCurrentUser()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSignUpTopNav = () => {
    setOpenSubscribeModal(true)
  }

  return (
    <div className={`mb-[17px] content focus:outline-none sm:mb-5`}>
      <Suspense fallback={<div></div>}>
        <LeadGenerationModal
          openModal={openSubscribeModal}
          setOpenModal={setOpenSubscribeModal}
          formType={'newsletter'}
          currentUser={currentUser}
        />
      </Suspense>
      <div
        onClick={() =>
          cardName === 'Newsletter' ? handleSignUpTopNav() : redirectToUrl(href)
        }
      >
        <img
          src={isMobile ? mobile : desktop}
          alt='Highlight 1 Image'
          className='rounded-3xl cursor-pointer'
          style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
        />
      </div>
    </div>
  );
}

export default HighlightsCard
