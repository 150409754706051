import clsx from 'clsx'
import { FaStarHalfAlt as HalfStar, FaStar as Star } from 'react-icons/fa'

type SolutionStarRatingProps = {
  solution: schema.Solution
  showDetails?: boolean
}

const SolutionStarRating = ({
  solution,
  showDetails = true
}: SolutionStarRatingProps) => {
  const roundedRating = (Math.round(Number(solution.rating) * 10) / 10).toFixed(1)

  return (
    <div className='flex flex-wrap items-center gap-2'>
      <div className='flex items-center gap-[2px]'>
        {Array.from({ length: 5 }).map((_, index) => {
          const starFilled = index + 1 <= Number(roundedRating)
          const hasHalfStar =
            Math.round(Number(roundedRating)) !==
            Math.floor(Number(roundedRating)) &&
            index + 1 === Math.round(Number(roundedRating))

          return (
            <div>
              {!starFilled && hasHalfStar ? (
                <HalfStar className={clsx(
                  `${'text-[0.65rem] sm:text-sm text-dark-blue'}`
                )} />
              ) : (
                <Star
                  className={clsx(
                    `stroke-dark-blue stroke-[10px] text-[0.65rem] sm:text-sm
                    ${starFilled ? 'text-dark-blue' : 'text-white'}`
                  )}
                />
              )}
            </div>
          )
        })}
      </div>
      {showDetails &&
        <>
          <div className='text-[10.8px] font-semibold text-dark-blue mt-0.5'>{roundedRating}</div>
          <div className='text-[10.8px] text-medium-gray mt-0.5'>
            ({solution.ratings_count || 0} ratings)
          </div>
        </>
      }
    </div>
  )
}

export default SolutionStarRating
