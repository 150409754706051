import Button from '@/components/atoms/buttons/Button';
import { useToken } from '@/components/atoms/forms/Form';
import Input from '@/components/atoms/inputs/Input';
import Layout from '@/components/organisms/layouts/Layout';
import { useForm, usePage } from '@inertiajs/react';
import { useState } from 'react';
import Meta from '@/components/atoms/widgets/Meta';
import window from 'global'

const SuccessView = () => (
  <div className="mb-10 flex flex-1 flex-col items-center justify-center space-y-6 text-center">
    <h1 className="text-5xl font-bold">Email Sent</h1>

    <p className="text-lg font-medium text-medium">
      Check your inbox and open the link to continue.
    </p>
  </div>
);

export default function ForgotPassword(props) {
  const [success, setSuccess] = useState(false);
  const pageProps = usePage();
  const token = useToken();
  const { data, setData } = useForm({ email: '' });
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch('/users/password.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
      body: JSON.stringify({
        user: {
          email: data.email,
        },
        authenticity_token: token,
      }),
    });

    if (response.ok) {
      setSuccess(true);
    } else {
      const json = await response.json();
      if (json.errors) {
        setError('Email not found');
      }
    }
  };

  const handleChange = (event) => {
    setError(null);
    setData(event.target.name, event.target.value);
  };

  return (
    <Layout>
      <Meta
        title="Password reset for your RecruitmentMarketing.com Marketplace Account -
          Access Top Recruitment Marketing SaaS and Services"
        description="Reset your password for your RecruitmentMarketing.com Marketplace account and access top recruitment marketing SaaS and services. Collaborate with vendors and streamline your hiring process."
      />
      <div className="flex w-full flex-1 flex-col md:max-w-[600px]">
        {!success ? (
          <form
            onSubmit={handleSubmit}
            acceptCharset="UTF-8"
            className="flex flex-1 flex-col"
          >
            <div className="flex h-full flex-1 flex-col justify-between lg:justify-start">
              <div className="mb-4 flex flex-col">
                <div className="mb-8 flex flex-col text-5xl font-bold md:mb-12 md:flex-row md:gap-2 lg:mb-[60px]">
                  <p>We'll email you a link to reset your password.</p>
                </div>
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={token}
                  autoComplete="off"
                />

                <Input
                  label="Email Address"
                  autoComplete="email"
                  type="email"
                  value={data.email}
                  name="email"
                  onChange={handleChange}
                  id="user_email"
                  required
                  errors={error}
                />
                {Boolean(error) && (
                  <div className="mt-4 text-danger-red">{error}</div>
                )}
              </div>

              <div className="flex flex-col space-y-4 md:flex-row-reverse md:space-y-0">
                <Button
                  variant="primary"
                  type="submit"
                  size="md"
                  className="block h-full w-full md:ml-4"
                  disabled={Boolean(error) || !data.email}
                >
                  Continue
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  size="md"
                  className="block h-full w-full"
                  onClick={() => window.history.back()}
                >
                  Go back
                </Button>
              </div>
            </div>
          </form>
        ) : (
          <SuccessView />
        )}
      </div>
    </Layout>
  );
}
