const MissionAccountSection = () => {
  return (
    <div className='text-center xl:pb-[5rem] lg:pt-[11rem] md:pt-[9rem] sm:pt-[4.875rem] sm:pb-16 lg:max-w-[602px] md:max-w-[578px] max-w-[352px] mx-auto pt-[4.8rem] pb-[2.75rem] sm:px-0 px-6'>
      <h1 className='lg:text-[2.813rem] md:text-[2rem] text-2xl font-extrabold'>
        Our Mission
      </h1>
      <p className='lg:text-[1.4rem] md:text-[1.049rem] lg:leading-10 md:leading-7 md:pt-[3.6rem] text-[0.875rem] leading-6 flex justify-center pt-[1.75rem] font-normal'>
        We are deeply familiar with the struggle of finding the perfect
        recruitment solutions. That's why we built RecruitmentMarketing.com – a
        trustworthy, data-driven platform dedicated to bridging the gap between
        talent acquisition practitioners and the recruitment marketing
        innovations that can enrich your company’s hiring practices. Our mission
        is to be your partner and help you elevate your recruitment strategies.
        From researching the newest technologies to sharing best practices, we
        are dedicated to delivering the best in talent acquisition. We empower
        you to attract the talent needed to grow your business. Join our (free)
        community and let’s support each other in shaping the future of hiring.
      </p>
    </div>
  )
}
export default MissionAccountSection
