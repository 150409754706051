export const imageValidation = (file, maxSize = 5) => {
  const validFileTypes =  ['image/jpeg', 'image/png', 'image/jpg']
  const maxFileSize = maxSize * 1024 * 1024
  return validateFile(file, 'Image', validFileTypes, maxFileSize)
}

export const documentValidation = (file) => {
  const validFileTypes =  ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf']
  const maxFileSize = 10 * 1024 * 1024
  return validateFile(file, 'Document', validFileTypes, maxFileSize)
}

export const videoValidation = (file) => {
  const validFileTypes =  ['video/mp4', 'video/webm', 'video/mpeg', 'video/m4v', 'video/quicktime']
  const maxFileSize = 100 * 1024 * 1024
  return validateFile(file, 'Video', validFileTypes, maxFileSize)
}

const validateFile = (file, fileType, allowedFileTypes, maxFileSize) => {
  let errors = []
  if(file.size > maxFileSize)
    errors.push(`Invalid ${fileType} size! ${fileType} size should not exceed ` + maxFileSize/(1024*1024) + ` Mb`)
  if(!allowedFileTypes.includes(file.type))
    errors.push(`Invalid ${fileType} Type!`)
  return errors
}