import React from 'react'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import { useMobileDetect } from '../../../utils/detectMobile'
import HeroImageMobileView from '../../../../assets/images/MarketplaceMobileHeroImage.png'
import Button from '@/components/atoms/buttons/Button'
import { IoChevronDown } from "react-icons/io5"

const MarketplaceHeroSection = ( { targetRef } ) => {
  const isMobile = useMobileDetect()
  const scrollToComponent = () => {
    const navbarHeight = document.querySelector('#navbar').offsetHeight;
    const top = targetRef.current.getBoundingClientRect().top + scrollY - navbarHeight;
    window.scrollTo({ top, behavior: 'smooth' });
  };

  const Details = () => {
    return(
      <div className='flex flex-col gap-4 sm:gap-6'>
        <p className='text-[14px] md:text-start text-black lg:text-[16px]'>
          Transform your talent acquisition practice with the technology, insights and expertise you need to automate, streamline, and hire more qualified talent, more quickly. As the first Marketplace dedicated to hiring pros, we’re committed to empowering you to find the best talent acquisition technology and services to grow your business.
        </p>
        <Button 
          variant='secondary'
          className='flex justify-center gap-1 items-center self-center md:self-start whitespace-nowrap px-3 py-2 text-[8px] !rounded-md md:text-xs mb-5 sm:mb-8 md:mb-10'
          onClick={scrollToComponent}
        >
          <p>View All Categories</p>
          <IoChevronDown className='text-xs md:text-md'/>
        </Button>
      </div>
    )
  }

  return (
    <HorizontalMarginsWrapper>
      <div className='flex flex-col font-Manrope md:items-start sm:items-center items-center justify-center sm:justify-start'>
        <div className='text-center md:text-start md:w-[44.5%] xl:w-[45%] pt-[50px] md:pt-[35px] lg:pt-[45px]'>
          <h1 className='font-extrabold text-[20px] md:text-[1.75rem] xl:text-[38px] leading-normal mb-10 sm:mb-4 lg:mb-8 px-2 sm:px-0'>
            Easily discover, compare, and shop leading recruitment technologies
          </h1>
          { !isMobile && <Details/> }
        </div>
      </div>
      { isMobile && 
        <div
          className='text-center md:text-start relative mt-[100px] mx-[4%] h-[300px] flex flex-col justify-end rounded-xl py-3 px-6'
          style={{
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.21) 0%, rgb(192, 235, 226) 100%)'
          }}
        >
          <Details/>
          <img
            src={HeroImageMobileView}
            alt='Hero-Image-Mobile'
            className='w-[274px] h-[195.453px] mt-[2%] absolute top-[-45%] xs:top-[-40%] self-center'
            loading='lazy'
          />
        </div>
      }
    </HorizontalMarginsWrapper>
  )
}

export default MarketplaceHeroSection