import React, { useState, useEffect } from 'react'
import { Popover } from '@headlessui/react'

import Navbar from '@/components/organisms/layouts/Navbar'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import AboutUsHeroSection from '@/components/organisms/heroSection/AboutUsHeroSection'
import OurServicesSection from '@/components/organisms/ourServices/OurServicesSection'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import marketplaceHero from '../../../assets/images/hero-section-img.png'
import {
  AboutUsBetaPartners,
  AboutUsCenterDiv,
  MeetMyTeam,
} from '@/components/organisms/AboutUs'
import MissionAccountSection from '@/components/organisms/missionAccountSection/MissionAccountSection'
import { useMobileDetect } from '../../utils/detectMobile'
import useCurrentUser from '@/hooks/useCurrentUser'
import Meta from '@/components/atoms/widgets/Meta'
import PageTitle from '@/components/atoms/widgets/PageTitle'

const outerMargins = 'pt-[4.875rem] md:pt-[7.625rem]'

const AboutUs = () => {
  const isMobile = useMobileDetect()

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const currentUser = useCurrentUser();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <PageTitle title='About | Recruitment Marketing' />
      <Meta title='About | Recruitment Marketing' />
      <div
        className={`flex w-full flex-col ${windowWidth < 1700 ? 'bg-cover' : 'bg-contain  bg-no-repeat'} bg-center md:h-[448px] lg:h-[500px] xl:h-[535px] pt-[52px] sm:pt-[78px]`}
        style={{
          backgroundImage: !isMobile && `url(${marketplaceHero})`,
        }}
      >
        <Popover className="flex flex-col z-50">
          {({ open }) => <Navbar open={open} />}
        </Popover>
        <AboutUsHeroSection />
      </div>
      <AboutUsBetaPartners />
      <HorizontalMarginsWrapper>
        <OurServicesSection />
      </HorizontalMarginsWrapper>
      <HorizontalMarginsWrapper classes='md:bg-gray-100'>
        <MissionAccountSection />
      </HorizontalMarginsWrapper>
      { ( currentUser?.newsletter_subscribed ) ? null : (
          <div className="md:bg-gray-100">
            <AboutUsCenterDiv />
          </div>
        )
      }
      <HorizontalMarginsWrapper classes='bg-gray-100'>
        <MeetMyTeam />
      </HorizontalMarginsWrapper>
      <div className={`${outerMargins} bg-gray-100`}>
        <FooterSection />
      </div>
    </>
  )
}

export default AboutUs
