import clsx from 'clsx';
import Button from '../../atoms/buttons/Button';

export function StartGeneratingQualityLeadsCTA({
  grayBackground = false,
}: {
  grayBackground: boolean;
}) {
  return (
    <div
      className={clsx(
        `top-0 flex flex-col justify-center ${
          grayBackground ? 'bg-light-gray' : 'bg-teal-tint-2'
        } p-10 text-center xl:py-9 xl:px-20`
      )}
    >
      <h4 className={'mb-3.5 text-[28px] font-bold leading-9 text-black'}>
        Start generating quality leads
      </h4>
      <p
        className={
          'mb-4 text-base font-medium text-black lg:mx-auto lg:max-w-[860px]'
        }
      >
        RecruitmentMarketing.com is the online hub for talent acquisition
        professionals to find the latest trends and insights, and discover the
        top tools for their HR needs. Reach our community of talent acquisition
        professionals and start generating quality leads!
      </p>
      <div>
        <a
          href="https://forms.gle/kqQ1kFbi6NoJiWZy8"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="primary" size="lg" className={'text-black'} trackBtnClick={true}>
            Add or Claim Solution
          </Button>
        </a>
      </div>
    </div>
  );
}
