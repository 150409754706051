import HybridLink from '@/components/atoms/widgets/HybridLink'
import { Link } from '@inertiajs/react'
import clsx from 'clsx'
import { Popover } from '@headlessui/react'
import { IoIosArrowBack } from 'react-icons/io'
import { addSolutionUrl } from '@/utils/constants'

const AllCategoriesSection = ({ funnelCategories, contentForModal, setShowCategoriesDropdown }) => {
  return (
    <div className={clsx('sm:shadow-none  rounded-2xl sm:mx-0 sm:px-0', { 'shadow-card px-5 mx-10 py-4 mt-10': !contentForModal })}>
      {contentForModal &&
        <Popover.Button className='flex mb-2 xl:hidden' onClick={() => { setShowCategoriesDropdown(false) }}>
          <p className='flex'>
            <span className='text-[17px] mt-1'><IoIosArrowBack /></span> Back
          </p>
        </Popover.Button>
      }
      <p
        className={clsx({
          'text-[1.2rem] xl:text-[1.5rem] font-extrabold': contentForModal,
          'xl:text-[2.375rem] sm:text-[1.75rem] text-[1.375rem] font-extrabold sm:text-center': !contentForModal,
        })}
      >
        All Categories
      </p>
      <div className={clsx(
        'flex flex-col xl:h-[480px] overflow-auto gap-2 mt-1 xl:mt-5',
        {
          'md:flex-wrap  md:h-[700px] h-[552px]': !contentForModal,
          'lg:flex xl:flex-wrap h-[480px]': contentForModal,
        }
      )}>
        {Object.keys(funnelCategories).map((key) => (
          <div key={key} className='mt-3'>
            <h3 className='font-bold text-[1rem] mb-2'>{key}</h3>
            {funnelCategories[key].map((obj, index) => (
              <Link href={`/categories/${obj.slug}`} key={index}>
                <p className='text-[1rem] leading-7 hover:text-cyan hover:underline underline-offset-4'>
                  {obj.name}
                </p>
              </Link>
            ))}
          </div>
        ))}
      </div>
      {contentForModal &&
        <div>
          <hr />
          <div className='flex justify-between py-2 sm:py-3'>
            <HybridLink href={addSolutionUrl}>
              <p className='text-sm tracking-tight hover:font-semibold'>Add Your Solution &gt;&gt;</p>
            </HybridLink>
            <Link href='/marketplace'>
              <p className='text-sm tracking-tight hover:font-semibold'>Visit Marketplace &gt;&gt;</p>
            </Link>
          </div>
        </div>
      }
    </div>
  )
}

export default AllCategoriesSection
