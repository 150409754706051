import React, { useState, useEffect } from 'react'
import mobileAboutUsHeroSection from '../../../../assets/images/mobilehero.png'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import { useMobileDetect } from '../../../utils/detectMobile';


const AboutUsHeroSection = () => {
  const isMobile = useMobileDetect()

  return (
    <>
      <HorizontalMarginsWrapper>
        <div className='flex flex-col font-Manrope md:items-start sm:items-center items-center'>
          <div className='pt-[70px] md:pt-[55px] lg:pt-[65px]'>
            <p className='text-[#42D0B0] font-extrabold text-[3.5rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.5rem]'>About us</p>
          </div>
          <div className='w-[65%] md:w-[44.5%] pt-[20px] xl:w-[45%] sm:pt-[33px] lg:pt-[17px]'>
            <p className='text-center text-[1rem] md:text-start text-black font-semibold  sm:text-[1.3rem] md:text-[0.76rem] lg:text-[0.85rem] xl:text-[1.0625rem]'>
              Transform your hiring strategy with RecruitmentMarketing.com. Founded
              by industry experts. Our platform offers talent
              acquisition and people leaders trusted insights and practical tools
              to improve hiring practices. We exist to help you succeed, and to
              empower you to attract top talent, build thriving talent pipelines,
              and hire the best talent to grow your business. Join us in shaping the
              future of hiring.
            </p>
          </div>
        </div>
      </HorizontalMarginsWrapper>
      <div>
        {isMobile && (
          <img
            src={mobileAboutUsHeroSection}
            alt='About Us Ad'
            className='mx-auto w-[900px] h-auto'
          />
        )}
      </div>
    </>
  )
}

export default AboutUsHeroSection
