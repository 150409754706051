import PromoHeader from '@/components/molecules/Marketplace/PromoHeader'
import PromoMobileHeader from '@/components/molecules/Marketplace/PromoMobileHeader'
import PromoSubHeader from '@/components/molecules/Marketplace/PromoSubHeader'

const PromoSection = () => {
  return (
    <div className='flex flex-col sm:mx-[-2rem] md:mx-auto'>
      <div className='md:hidden'>
        <PromoMobileHeader />
      </div>
      <div className='hidden md:block mx-8'>
        <PromoHeader />
      </div>
      <PromoSubHeader />
    </div>
  )
}

export default PromoSection
