import { Suspense, lazy, useState } from 'react';
import Input from '@/components/atoms/inputs/Input';
const AddMarketingMaterialModal = lazy(() => import('@/components/molecules/modals/AddMarketingMaterialModal'));
import PlusIcon from '../../../../assets/images/plus.svg';
import { imageValidation } from '@/utils/fileValidations';
import { toast } from 'react-toastify';
import Button from '@/components/atoms/buttons/Button';

const EditOurCustomers = ({ setData, data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalFor, setModalFor] = useState('');
  const showValidationErrors = (errors) => {
    if (errors.length > 0) {
      toast(
        <div>
          {errors.map((error) => {
            return <p>{error}</p>;
          })}
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          className:
            'bg-red-200 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
          bodyClassName: 'p-0 min-w-[324px]',
        }
      );
      setOpenModal(false);
      return;
    }
  };

  const handleCustomerImageChange = (logo, index) => {
    let validationErrors = null;
    if (logo !== null) {
      validationErrors = imageValidation(logo);
      if (validationErrors.length > 0) {
        showValidationErrors(validationErrors);
        return;
      }
    }
    setData((prevData) => {
      const updatedCustomers = prevData.customers_attributes.map(
        (customer, position) => {
          if (position === index) {
            return { ...customer, logo: logo };
          }
          return customer;
        }
      );
      return { ...prevData, customers_attributes: updatedCustomers };
    });
    setOpenModal(false);
  };

  const handleTitleChange = (title, index) => {
    setData((prevData) => {
      const updatedCustomers = prevData.customers_attributes.map(
        (customer, position) => {
          if (position === index) {
            return { ...customer, title: title };
          }
          return customer;
        }
      );
      return { ...prevData, customers_attributes: updatedCustomers };
    });
  };

  const handleDelete = (customerId) => {
    setData((prevData) => {
      const updatedCustomers = prevData.customers_attributes.map((customer) => {
        if (customer.id === customerId) {
          return { ...customer, _destroy: true };
        }
        return customer;
      });
      return { ...prevData, customers_attributes: updatedCustomers };
    });
  };

  const handleModalInput = (index, modalHeading) => {
    setOpenModal(true);
    setActiveIndex(index);
    setModalFor(modalHeading);
  };

  return (
    <div className='sm:p-8 p-4'>
      <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 justify-items-center gap-2'>
        {data.customers_attributes.map(
          (customer, index) =>
            !customer._destroy && (
              <div className='rounded-xl relative' key={index}>
                {customer.logo_url ? (
                  <div className='flex justify-center'>
                    <img
                      src={customer.logo_url}
                      alt='download-thumbnail'
                      className='rounded-full lg:w-[7.5rem] lg:h-[7.5rem] w-[6.25rem] h-[6.25rem] sm:mt-[3rem] mt-[2rem] object-cover'
                      loading='lazy'
                    />
                  </div>
                ) : customer.logo ? (
                  <div className='flex justify-center'>
                    <img
                      src={URL.createObjectURL(customer.logo)}
                      alt='download-thumbnail'
                      className='rounded-full lg:w-[7.5rem] lg:h-[7.5rem] w-[6.25rem] h-[6.25rem] sm:mt-[3rem] mt-[2rem] object-cover'
                      loading='lazy'
                    />
                  </div>
                ) : (
                  <div className='flex justify-center'>
                    <div
                      className='flex items-center justify-center border-dashed border-gray-300 border-2 rounded-full lg:min-w-[7.5rem] lg:h-[7.5rem] min-w-[6.25rem] h-[6.25rem] sm:mt-[3rem] mt-[2rem]'
                      key={index}
                    >
                      <img
                        src={PlusIcon}
                        alt='plus icon'
                        onClick={() => handleModalInput(index, 'Image')}
                        className='cursor-pointer h-8 w-8'
                        loading='lazy'
                      />
                    </div>
                    <input
                      type='file'
                      id={`fileInput_${index}`}
                      style={{ display: 'none' }}
                      onChange={(e) =>
                        handleCustomerImageChange(e.target.files[0], index)
                      }
                    />
                  </div>
                )}
                <Input
                  type='text'
                  label='Title:'
                  labelHtmlFor={`customer_description_${index}`}
                  id={`customer_description_${index}`}
                  name={`customer_description_${index}`}
                  value={customer.title}
                  containerClass='my-7'
                  onChange={(e) => handleTitleChange(e.target.value, index)}
                  maxLength={15}
                />
                <div className='flex'>
                  <div
                    className={`text-xs font-medium ${customer.title?.length > 24 ? 'text-danger-red' : 'text-[#8A8DA0]'}`}
                  >
                    Max 15 characters
                  </div>
                  <div
                    className={`ml-auto text-xs font-medium ${customer.title?.length > 24 ? 'text-danger-red' : 'text-[#8A8DA0]'}`}
                  >
                    {customer.title?.length}
                  </div>
                </div>
                {customer.id && (
                  <Button
                    variant='dangerLight'
                    size='md'
                    className='my-[1.5rem]'
                    onClick={() => handleDelete(customer.id)}
                  >
                    Delete Item
                  </Button>
                )}
              </div>
            )
        )}
      </div>
      <Suspense fallback={<div></div>}>
        <AddMarketingMaterialModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleFileChange={handleCustomerImageChange}
          index={activeIndex}
          modalFor={modalFor}
        />
      </Suspense>
    </div>
  );
};

export default EditOurCustomers;