import React, { lazy, useState } from 'react'
import { useMobileDetect } from '../../../utils/detectMobile'
import { ParentCategories } from '@/utils/constants'
import { Funnel } from '@/components/atoms/Marketplace/FunnelSection/Funnel'

const Breadcrumbs = lazy(() => import('../../atoms/widgets/Breadcrumbs'))

const MarketplaceFunnelSection = () => {
  const isMobile = useMobileDetect()
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0)
  const activeCategory = ParentCategories[activeCategoryIndex]

  const FunnelCategoryDetails = () => {
    return (
      <div className='w-full md:w-[50%] flex flex-col gap-10 p-10'>
        <div className='flex flex-col gap-3'>
          <p className='text-[20px] lg:text-[24px] font-extrabold'>
            {activeCategory.title}
          </p>
          <p className='text-sm lg:text-base'>
            {activeCategory.description}
          </p>
        </div>
        <div className='flex flex-col gap-3'>
          <p className='text-sm lg:text-base font-extrabold'>Pain points:</p>
          <ul className='list-disc gap-3 flex flex-col'>
            {activeCategory.painPoints.map((point, index)=>{
              return (
                <li key={index} className='text-xs lg:text-base text-funnel-gray'>
                  {point}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col justify-center items-center md:text-start mx-10 sm:mx-0 my-10'>
      <p className='font-extrabold text-[20px] md:text-[1.75rem] xl:text-[38px] leading-normal mb-6 lg:mb-8 text-center'>
        Find solutions for any hiring challenge
      </p>
      <div className='flex flex-col-reverse md:flex-row w-full shadow-card rounded-xl h-auto p-2'>
        <FunnelCategoryDetails />
        { 
          isMobile && 
            <Breadcrumbs
              items={ParentCategories}
              activeIndex={activeCategoryIndex}
              setActiveIndex={setActiveCategoryIndex}
            />
        }
        <Funnel
          activeIndex={activeCategoryIndex}
          setActiveIndex={setActiveCategoryIndex}
        />
      </div>
    </div>
  )
}

export default MarketplaceFunnelSection