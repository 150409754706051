import { getCanvasEmbedToken } from '@/utils/axiosRequests'
import { Link } from '@inertiajs/react'
import clsx from 'clsx'
import React, { lazy, startTransition, useEffect, useState } from 'react'
const Canvas = lazy(() =>
  import('canvas-embed').then((module) => ({ default: module.Canvas }))
)
import { FaChevronDown as Chevron } from 'react-icons/fa6'
import { ThreeDots } from 'react-loader-spinner'
import './style.css' 

type ProfilePageSolutionCardProps = {
  solution: schema.Solution
}

const ProfilePageSolutionCard = ({
  solution,
}: ProfilePageSolutionCardProps) => {
  const [canvasDashboardToken, setCanvasDashboardToken] = useState(null)
  const [toggleCanvasDashboard, setToggleCanvasDashboard] = useState(false)
  const [isCanvasEmbedLoaded, setIsCanvasEmbedLoaded] = useState(false)
  const [canvasDashboardPrompt, setCanvasDashboardPrompt] = useState('Show Dashboard')

  const canvasId = import.meta.env.VITE_CANVAS_DASHBOARD_ID
  const solutionName = solution.name.toLowerCase().split(' ').join('-')

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getCanvasEmbedToken(solutionName)
      setCanvasDashboardToken(token)
    }
    fetchToken()
  }, [])

  useEffect(() => {
    if (toggleCanvasDashboard && !isCanvasEmbedLoaded) {
      setCanvasDashboardPrompt('Loading Dashboard')
    }
    else if (toggleCanvasDashboard && isCanvasEmbedLoaded) {
      setCanvasDashboardPrompt('Hide Dashboard')
    }
    else {
      setCanvasDashboardPrompt('Show Dashboard')
    }
  }, [toggleCanvasDashboard, isCanvasEmbedLoaded])

  const handleToggleCanvasDashboard = () => {
    startTransition(() => {
      setToggleCanvasDashboard((prevState) => !prevState)
      if (!isCanvasEmbedLoaded) {
        const callback = (mutationsList, obs) => {
          for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
              const canvasEmbed = document.querySelector(
                `#${solutionName} .canvas-embed`
              )
              if (canvasEmbed) {
                setIsCanvasEmbedLoaded(true)
                obs.disconnect()
                break
              }
            }
          }
        }
        const observer = new MutationObserver(callback)
        observer.observe(document.body, { childList: true, subtree: true })
      } else setIsCanvasEmbedLoaded((prevState) => !prevState)
    })
  }

  return (
    <div className='flex flex-col'>
      <div className='relative flex flex-col w-full bg-light-gray border border-custom-gray-2 sm:p-8 py-8 px-4 justify-between items-center text-clip rounded-xl hover:outline hover:outline-1 hover:outline-medium-gray'>
        <div className='flex justify-between w-full'>
          <div className='flex items-center pr-3'>
            <img
              className='sm:h-[48px] h-[36px] sm:w-[48px] w-[36px] rounded-full'
              src={solution?.logo_url}
              alt='Solution Logo'
              rel='nofollow'
            />
            <Link
              href={`/solutions/${solution.slug}`}
              className={`flex items-center gap-2 break-all mb-0 underline sm:text-lg text-sm text-left font-Manrope sm:font-bold font-medium uppercase text-dark-blue line-clamp-3 pl-3 cursor-pointer`}
              style={{ letterSpacing: '1px' }}
            >
              <p>{solution.name}</p>
              <Chevron className='-rotate-90' />
            </Link>
          </div>
          <div
            className='flex items-center justify-center gap-3 cursor-pointer'
            onClick={canvasDashboardPrompt  != 'Loading Dashboard' ? handleToggleCanvasDashboard : ()=>{}}
          >
            <p
              className={clsx(
                canvasDashboardPrompt != 'Loading Dashboard' && 'font-bold'
              )}
            >
              {canvasDashboardPrompt}
            </p>
            {canvasDashboardPrompt != 'Loading Dashboard' ? (
              <Chevron className={clsx(toggleCanvasDashboard && 'rotate-180')} />
            ) : (
              <ThreeDots
                visible={true}
                height='30'
                width='30'
                color='teal'
                radius='9'
                ariaLabel='three-dots-loading'
              />
            )}
          </div>
        </div>
        {toggleCanvasDashboard && (
          <div className='w-full' id={`${solutionName}`}>
            <Canvas canvasId={canvasId} authToken={canvasDashboardToken} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfilePageSolutionCard
