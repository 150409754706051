import { useState, useEffect, useRef } from 'react'
import { Popover } from '@headlessui/react'
import Navbar from '@/components/organisms/layouts/Navbar'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import MarketplaceHeroSection from '@/components/molecules/Marketplace/MarketplaceHeroSection'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import HeroImage from '../../../../app/assets/images/HeroBgMarketplace.png'
import { useMobileDetect } from '@/utils/detectMobile'
import SolutionEvaluator from '@/components/organisms/Marketpalce/SolutionEvaluator'
import PromoSection from '@/components/organisms/Marketplace/PromoSection'
import AllCategoriesSection from './AllCategoriesSection'
import SolutionTicker from '@/components/organisms/Marketpalce/SolutionTicker'
import MarketplaceFunnelSection from '@/components/molecules/Marketplace/MarketplaceFunnelSection'
import NavbarSearch from '@components/molecules/navbar/NavbarSearch'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import Meta from '@/components/atoms/widgets/Meta'
import { CompareSolutionTab } from '@/components/molecules/compareSolution/CompareSolutionTab'
import useComparedSolutions from '@/hooks/useComparedSolutions'

type MarketplaceLandingPageProps = {
  paid_solutions: schema.Solution[]
  funnelCategories: schema.ParentCategory[]
}

const MarketplaceLandingPage = (
  { paid_solutions, funnelCategories }: MarketplaceLandingPageProps) => {

  const isMobile = useMobileDetect()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const targetRef = useRef(null)
  const [solutionsToCompare, setSolutionsToCompare] = useComparedSolutions()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <PageTitle title='Marketplace | Recruitment Marketing' />
      <Meta title='Marketplace | Recruitment Marketing' />
      <div
        className={`flex w-full flex-col ${windowWidth < 1700 ? 'bg-cover bg-origin-content' : 'bg-contain  bg-no-repeat'} bg-center h-auto pt-[52px] sm:pt-[78px] object-scale-down`}
        style={{
          backgroundImage: !isMobile && `url(${HeroImage})`
        }}
      >
        <Popover className="flex flex-col z-50">
          {({ open }) => <Navbar open={open} />}
        </Popover>
        {
          isMobile && 
          <div className='mt-10'>
            <NavbarSearch />
          </div>
        }
        <MarketplaceHeroSection targetRef={targetRef} />
      </div>
      <div className='relative top-[-15px] '>
        <SolutionTicker solutions={paid_solutions}/>
      </div>
      <HorizontalMarginsWrapper>
        <SolutionEvaluator/>
        <MarketplaceFunnelSection />
        <div ref={targetRef}>
          <AllCategoriesSection funnelCategories={funnelCategories} contentForModal={false} />
        </div>
        <PromoSection />
      </HorizontalMarginsWrapper>
      {solutionsToCompare?.length > 0 && (
              <div className='sticky bottom-0 z-[10] bg-[#ECEDF1] shadow-sticky-bar'>
                <CompareSolutionTab
                  solutionsToCompare={solutionsToCompare}
                  setSolutionsToCompare={setSolutionsToCompare}
                />
              </div>
            )}
      <FooterSection />
    </>
  )
}

export default MarketplaceLandingPage
