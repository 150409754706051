import Events from '../../../../assets/images/services-industry-events.svg'
import NewsAndInsights from '../../../../assets/images/services-industry-news-and-insights.svg'
import NewsInsights from '../../../../assets/images/services-news-and-insights.svg'
import OurMarketplace from '../../../../assets/images/services-our-marketplace.svg'
import IndustryEvents from '../../../../assets/images/services-recruiting-industry-events.svg'
import SolutionMarketplace from '../../../../assets/images/services-solutions-marketplace.svg'

export const ourServicesCards = [
  {
    id: 1,
    image: SolutionMarketplace,
    heading: <>Solutions Marketplace</>,
    class: 'md:w-[49px] md:h-[70px] lg:w-[83.9px] lg:h-[119.16px]'
  },
  {
    id: 2,
    image: NewsAndInsights,
    heading: <>Industry News & insights</>,
    class: 'md:w-[68.86px] md:h-[61.15px] lg:w-[120.7px] lg:h-[106.8px]'
  },
  {
    id: 3,
    image: IndustryEvents,
    heading: <>Recruiting Industry Events</>,
    class: 'md:w-[77.3px] md:h-[60.25px] lg:w-[123.1px] lg:h-[94.2px]'
  }
]

export const cardsInsights = [
  {
    id: 1,
    image: OurMarketplace,
    heading: "Our Marketplace",
    paragraph: "Finding technology has never been easier. You can research and compare recruitment technology directly on our marketplace, designed specifically for talent acquisition practitioners. Save your team time by sharing research, reviewing demos, comparing different product features, all in one place. By streamlining the tech procurement process, you can devote more attention to hiring qualified talent to grow your business. Watch your accepted-offers rate soar!"
  },
  {
    id: 2,
    image: NewsInsights,
    heading: "News & Insights",
    paragraph: "We deliver recruitment marketing industry news. Explore our curated news and content, in-depth analysis, and trusted expert insights to fuel your talent acquisition strategies."
  },
  {
    id: 3,
    image: Events,
    heading: "Industry Events",
    paragraph: "Be the first to know about upcoming industry events near you and around the world. Find tickets to your next conference and unleash new learning and networking opportunities to grow your business. Access our exclusive discounts by subscribing to our newsletter."
  }
]

export const servicesMobileCards = [
  {
    id: 1,
    icon: SolutionMarketplace,
    heading: <>Solutions <br /> Marketplace</>,
    paragraph: "Finding technology has never been easier. You can research and compare recruitment technology directly on our marketplace, designed specifically for talent acquisition practitioners. Save your team time by sharing research, reviewing demos, comparing different product features, all in one place. By streamlining the tech procurement process, you can devote more attention to hiring qualified talent to grow your business. Watch your accepted-offers rate soar!",
    image: OurMarketplace
  },
  {
    id: 2,
    icon: NewsAndInsights,
    heading: <>Industry <br /> News & insights</>,
    paragraph: "We deliver recruitment marketing industry news. Explore our curated news and content, in-depth analysis, and trusted expert insights to fuel your talent acquisition strategies.",
    image: NewsInsights
  },
  {
    id: 3,
    icon: IndustryEvents,
    heading: <>Recruiting <br /> Industry Events</>,
    paragraph: "Be the first to know about upcoming industry events near you and around the world. Find tickets to your next conference and unleash new learning and networking opportunities to grow your business. Access our exclusive discounts by subscribing to our newsletter.",
    image: Events
  }
]

export const servicesHeroCards = [
  {
    id: 1,
    title: 'Join',
    value: '100k+',
    description: <>talent professionals <br /> using our platform.</>
  },
  {
    id: 2,
    title: 'Discover',
    value: '1,500+',
    description: <>talent solutions, peer-reviewed <br /> and listed on our Marketplace.</>
  },
  {
    id: 3,
    title: 'Explore',
    value: '500+',
    description: <>industry conferences <br /> and events.</>
  },
]
