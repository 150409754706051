import { lazy } from 'react';
import linkedinIcon from '../../../../assets/images/black-linkedin-icon.png'

const HybridLink = lazy(() => import('@/components/atoms/widgets/HybridLink'))

type SolutionCardProps = {
  name: string;
  designation: string;
  linkedinUrl: string;
  imgSrc: string;
}

const TeamMemberCard = ({ name, designation, linkedinUrl, imgSrc } : SolutionCardProps ) => (
    <div className='bg-white w-[130px] md:w-[156px] lg:w-[222px] h-auto rounded-xl md:rounded-2xl' style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <div className='flex flex-col items-center justify-center pt-3 pb-4 lg:pt-6 lg:pb-8 md:pt-4 md:pb-6'>
          <div>
            <img src={imgSrc} alt={name} className='w-[78px] h-[83px] lg:w-[153px] lg:h-[162px] md:w-[108px] md:h-[115px]'/>
          </div>
          <div className='flex flex-col items-center'>
            <h3 className='text-[12px] md:text-[12.4px] lg:text-[17.4px] font-semibold text-black'>{name}</h3>
            <p className='text-[10px] md:text-[10px] lg:text-[13.4px] font-semibold text-black text-wrap text-center px-4'>{designation}</p>
            <HybridLink
              href={linkedinUrl}
              isExternal={true}
              className='mt-2 md:mt-3'
            >
              <img src={linkedinIcon} alt='linkedin-icon' className='w-[21px] h-[21px] md:w-[21px] md:h-[21px] lg:w-[30px] lg:h-[30px]' />
            </HybridLink>
          </div>
        </div>
    </div>
  )

export default TeamMemberCard

