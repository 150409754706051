import HybridLink from '@/components/atoms/widgets/HybridLink'
import { MLP_PROMO_LINK } from '@/utils/constants'

const PromoSubHeader = () => {
  return (
    <div className='flex flex-col gap-4 md:gap-2 items-center justify-center bg-[#D1EBEB] h-[265px] sm:h-[282px] 
        lg:h-[421px] md:rounded-xl md:mb-16 lg:mb-12 md:pt-12'>
      <h2 className='font-extrabold text-center w-[210px] md:w-full text-[22px] md:text-[28px] lg:text-[38px]'>
        Partner with us
      </h2>
      <p className='text-center text-[16px] w-[225px] md:w-full text-[15.33px] lg:text-[22.9px]'>
        Connect with only one buyer - talent acquisition leaders
      </p>
      <HybridLink
        isExternal={true}
        href={MLP_PROMO_LINK}
        className='cursor-pointer text-center text-sm text-white bg-custom-blue py-2 rounded-[5.5px] min-w-[140px] mt-4 lg:mt-8'
      >
        Get Started
      </HybridLink>
    </div>
  )
}

export default PromoSubHeader
