import { useState, Suspense } from 'react'

import Button from '@/components/atoms/buttons/Button'
import HomepageSearch from '@/Pages/Homepage/HomepageSearch'
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal'
import useCurrentUser from '@/hooks/useCurrentUser'

const MarketplaceHeroSection = () => {
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false)

  const currentUser = useCurrentUser()

  const handleSignUpTopNav = () => {
    setOpenSubscribeModal(true)
  }

  return (
    <div className='flex flex-col items-center justify-center py-[1.7rem] sm:py-[2.5rem] xl:py-[5.5rem]'>
      <p className='mb-[9.31px] sm:mb-[23px] items-center text-[20px] -tracking-[0.5px] font-extrabold leading-[27px] text-white drop-shadow-[0_2px_5px_rgba(0,0,0,0.5)] sm:text-[2.5rem] sm:leading-[2.695rem]'>
        Join the leading community of <br /> talent acquisition professionals
      </p>
      <p className='w-[300px] leading-normal text-[10px] text-white sm:w-[680px] sm:text-[0.937rem] mb-3 font-bold '>
        We're here to help you shape the future of hiring. Our platform features news, industry insights, and a Marketplace to help you discover the right tools to do your job better.
      </p>

      <HomepageSearch/>

     { (currentUser?.newsletter_subscribed) ? null :
        <Button
          variant='secondary'
          className='flex items-center justify-center py-2 px-4 mt-2 sm:py-2 sm:px-8 sm:mt-8 text-[8px] sm:text-xs font-bold !rounded-[8px]'
          onClick={handleSignUpTopNav}
        >
              Sign Up For Free
        </Button>
      }
      <Suspense fallback={<div></div>}>
        <LeadGenerationModal
          openModal={openSubscribeModal}
          setOpenModal={setOpenSubscribeModal}
          formType={'newsletter'}
          currentUser={currentUser}
        />
      </Suspense>
    </div>
  )
}

export default MarketplaceHeroSection
