import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchIcon from '../../../assets/images/icon-search.svg'
import closeIcon from '../../../assets/images/close-icon-gray.svg';
import { useTracking } from 'react-tracking'
import { useDebounce } from '@/hooks/useDebounce';
import SearchDropdown from '@/components/molecules/homepageSearch/SearchDropdown';

const HomepageSearch = () => {
  const DEBOUNCE_DELAY = 2000;
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query)
  const trackedQuery = useDebounce(query, DEBOUNCE_DELAY)
  const [results, setResults] = useState({ solutions: [], articles: [], events: [], categories: [] });
  const [showDropdown, setShowDropdown] = useState(false);
  const tracking = useTracking();
  const sections = [
    { results: results.categories, heading: 'Categories' },
    { results: results.articles, heading: 'Articles' },
    { results: results.solutions, heading: 'Solutions' },
    { results: results.events, heading: 'Events' }
  ];

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get('/search', { params: { query: debouncedQuery } });
        setResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        // Error Handling Here
      }

    };
    if (debouncedQuery) {
      fetchSearchResults();
    } else {
      setResults({ solutions: [], articles: [], events: [], categories: [] });
      setShowDropdown(false);
    }
  }, [debouncedQuery]);

  useEffect(()=>{
    if (trackedQuery === '') return;
    
    tracking.trackEvent({
      event_name: 'Homepage Search',
      page_url: window.location.pathname,
      event_loggable_type: "RmcEvents::SearchLog",
      additional_attributes: { searched_term: trackedQuery }
    });
  }, [trackedQuery]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelectResult = (result) => {
    let visit_info = null;
    const pathMap = {
      'Solution': '/solutions/',
      'Category': '/categories/'
    };
    visit_info = pathMap[result.model_name] ? `${pathMap[result.model_name]}${result.slug}` : result.page_url;
    if (visit_info) {
      tracking.trackEvent({
        event_name: 'Homepage Search',
        page_url: window.location.pathname,
        event_loggable_type: "RmcEvents::SearchLog",
        additional_attributes: { searched_term: trackedQuery, clicked_url: visit_info }
      });
      window.open(visit_info, '_blank');
    }
  };

  const handleSearchDropdown = () => {
    setQuery('');
    setShowDropdown(false);
  }

  return (
    <div className='flex justify-center relative'>
      <div className='block'>
      <div className="flex items-center shadow-lg relative">
        <input className="md:w-[30rem] sm:h-[2.4rem] h-[2rem] w-[20rem] rounded-lg !outline-none hover:cursor-text border-none placeholder-gray-300 pl-10" type="text" placeholder="Search..." value={query} onChange={handleChange}/>
        <img src={SearchIcon} alt="search icon" className="absolute left-2 w-[20px] h-[20px]"/>
        {showDropdown && <img src={closeIcon} className='absolute right-2 w-7 h-7 hover:cursor-pointer' onClick={handleSearchDropdown}/>}
      </div>
        {showDropdown && (
          <div className="drop-shadow-lg z-10 p-5 dropdown bg-white md:w-[30rem] xl:h-[14.5rem] lg:h-[15rem] md:h-[13rem] w-[20rem] rounded-lg absolute max-h-60 overflow-y-scroll">
            <div className="dropdown-menu text-left">
              <div className="dropdown-content">
              {sections.map((section, index) => (
                <React.Fragment key={index}>
                  <SearchDropdown 
                    results={section.results} 
                    heading={section.heading} 
                    handleSelectResult={handleSelectResult} 
                  />
                  {index < sections.length - 1 && <div className='border-t border-gray-200 my-2'></div>}
                </React.Fragment>
              ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomepageSearch;
