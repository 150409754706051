import CategoryDefaultImg from '../../../../assets/images/category-search-default.png'
import DefaultImage from '../../../../assets/images/default_image.png'
import decodeHtmlEntities from '@/utils/decodeHtmlEntities'

const SearchDropdown = ({ heading, results, handleSelectResult }) => {
  const getImgSrc = (result) => {
    return heading === 'Solutions'
            ? result.logo_url
            : result.image_url !== null
              ? result.image_url
              : DefaultImage
  }
  return (
    <>
      <h1 className='font-bold text-[1rem]'>{heading}</h1>
      {!results.length ? (
        <h4 className='text-gray-300 text-center'>No Matched Data</h4>
      ) : (
        results.map((result) => (
          <div
            key={result.id}
            className='dropdown-item hover:cursor-pointer'
            onClick={() => handleSelectResult(result)}
          >
            <div className='flex mt-3 items-center hover:underline transition-all duration-300 ease-in-out'>
              {heading !== 'Categories' &&
              (result.image_url !== null || result.logo_url !== null) ? (
                <img
                  className='md:w-[35px] md:h-[35px] w-[40px] h-[40px] rounded-full object-cover'
                  src={getImgSrc(result)}
                  alt='logo'
                  loading='lazy'
                />
              ) : (
                <img
                  className='md:w-[35px] md:h-[35px] w-[40px] h-[40px] rounded-full object-cover'
                  src={
                    heading === 'Categories' ? CategoryDefaultImg : DefaultImage
                  }
                  alt='logo'
                  loading='lazy'
                />
              )}
              <p className='ml-3 text-xs font-semibold'>
                {['Solutions', 'Categories'].includes(heading)
                  ? result.name
                  : decodeHtmlEntities(result.title)}
              </p>
            </div>
          </div>
        ))
      )}
    </>
  )
}
export default SearchDropdown
