import { useState } from 'react'

import Modal from '@components/atoms/modals/Modal'
import ImageCropComponent from '@components/molecules/modals/ImageCropComponent'

import Button from '@/components/atoms/buttons/Button'

type ImagePreviewModalProps = {
  openModal: boolean
  setOpenModal: (open: boolean) => void
  src: string
  handleFileChange: (
    file: File | null,
    type: string,
    maxSize: number,
    urlSetter: (url: string) => void
  ) => void
  previewUrlSetter: (url: string) => void
  MAX_HERO_IMAGE_SIZE: number
}

const ImagePreviewModal = ({
  openModal,
  setOpenModal,
  src,
  handleFileChange,
  previewUrlSetter,
  MAX_HERO_IMAGE_SIZE,
}: ImagePreviewModalProps) => {
  const [croppedFile, setCroppedFile] = useState(null)

  const handleSave = () => {
    handleFileChange(croppedFile, 'hero_image', MAX_HERO_IMAGE_SIZE, previewUrlSetter)
  }
 
  return (
    <Modal
      isOpen={openModal}
      onClose={() => {
        setOpenModal(false)
      }}
      closeStyle='top-3'
      className='!mx-[20px] w-[100%] h-[600px] gap-5 rounded-xl bg-white px-9 pb-6 pt-12 md:mt-0'
    >
      <h1 className='mt-[-35px] text-2xl font-bold text-gray-600'>Crop your photo</h1>
      <div className='mt-2'>
       <ImageCropComponent src={src} setCroppedFile={setCroppedFile}/>
      </div>
      <div className='mt-4 flex flex-row justify-end gap-3'>
      <Button variant='gray' size='md' onClick={() => setOpenModal(false)}> Cancel </Button>
      <Button variant={`${croppedFile? 'teal' : 'disable'}`} size='md' onClick={() => croppedFile? handleSave() : null}> Save</Button>
      </div>
    </Modal>
  )
}

export default ImagePreviewModal
