import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SearchIcon from '../../../../assets/images/icon-search.svg'
import { useTracking } from 'react-tracking'
import { useDebounce } from '@/hooks/useDebounce'
import SearchDropdown from '@/components/molecules/homepageSearch/SearchDropdown'
import { IoSearch } from 'react-icons/io5'

const NavbarSearch = ({hideDropdown, setHideDropdown, errorPage} : {hideDropdown?: boolean, setHideDropdown?: React.Dispatch<React.SetStateAction<boolean>>, errorPage?: boolean} ) => {
  const DEBOUNCE_DELAY = 2000
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)
  const trackedQuery = useDebounce(query, DEBOUNCE_DELAY)
  const [results, setResults] = useState({ solutions: [], articles: [], events: [], categories: [] })
  const [showDropdown, setShowDropdown] = useState(false)
  const tracking = useTracking()
  
  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get('/search', { params: { query: debouncedQuery } })
        setResults(response.data)
        setShowDropdown(true)
      } catch (error) {
        // Error Handling Here
      }

    }
    if (debouncedQuery) {
      fetchSearchResults()
    } else {
      setResults({ solutions: [], articles: [], events: [], categories: [] })
      setShowDropdown(false)
    }
  }, [debouncedQuery])

  useEffect(()=>{
    if (trackedQuery === '') return
    
    tracking.trackEvent({
      event_name: 'Homepage Search',
      page_url: window.location.pathname,
      event_loggable_type: 'RmcEvents::SearchLog',
      additional_attributes: { searched_term: trackedQuery }
    })
  }, [trackedQuery])

  useEffect(()=>{
    if (hideDropdown) {
      setShowDropdown(false)
      setQuery('')
    }
  },[hideDropdown])

  const handleChange = (e) => {
    setQuery(e.target.value)
    setHideDropdown(false)
  }

  const handleSelectResult = (result) => {
    let visit_info = null
    const pathMap = {
      'Solution': '/solutions/',
      'Category': '/categories/'
    }
    visit_info = pathMap[result.model_name] ? `${pathMap[result.model_name]}${result.slug}` : result.page_url
    if (visit_info) {
      tracking.trackEvent({
        event_name: 'Homepage Search',
        page_url: window.location.pathname,
        event_loggable_type: 'RmcEvents::SearchLog',
        additional_attributes: { searched_term: trackedQuery, clicked_url: visit_info }
      })
      window.open(visit_info, '_blank')
    }
  }

  return (
    <>
      <div className='flex justify-center relative rounded-lg'>
        <div className='block'>
          <div className='flex items-center relative'>
            <input className={errorPage? `sm:h-[2.776rem] h-[2.5rem] lg:!w-[29.5rem] xxs:w-[24rem] w-[325px] focus:border-none rounded-full !outline-none border-[2px]
            border-[#D9D9D9] hover:cursor-text placeholder-[#747474] pl-10 text-md drop-shadow-md sm:drop-shadow-none`: `md:w-[18rem] sm:h-[2rem] h-[2.5rem] w-[20rem] focus:border-none rounded-lg sm:rounded-full !outline-none
            border-gray-300 hover:cursor-text placeholder-gray-300 pl-10 text-xs drop-shadow-md sm:drop-shadow-none` }
              type='text'
              placeholder={errorPage? 'Search Marketplace' :'Search marketplace...'}
              value={query} onChange={handleChange}
            />
            {errorPage? <IoSearch  color='#747474' className='absolute left-3 size-[24px]'/> : <img src={SearchIcon} alt='search icon' className='absolute left-2 w-[20px] h-[20px]' /> }
          </div>
          {(!hideDropdown && showDropdown) && (
            <div className='bg-white drop-shadow-lg z-40 p-5 md:w-[18rem] md:h-[20rem] w-[20rem] rounded-lg absolute max-h-96 overflow-y-scroll'>
              <div className='dropdown-menu text-left'>
                <div className='dropdown-content'>
                  <SearchDropdown results={results.categories} heading={'Categories'} handleSelectResult={handleSelectResult} />
                  <div className='border-t border-gray-200 my-2'></div>
                  <SearchDropdown results={results.articles} heading={'Articles'} handleSelectResult={handleSelectResult} />
                  <div className='border-t border-gray-200 my-2'></div>
                  <SearchDropdown results={results.solutions} heading={'Solutions'} handleSelectResult={handleSelectResult} />
                  <div className='border-t border-gray-200 my-2'></div>
                  <SearchDropdown results={results.events} heading={'Events'} handleSelectResult={handleSelectResult} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default NavbarSearch
