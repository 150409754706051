import React from 'react'
import BetaPartners from '../../../../assets/images/Partner_logo_ticker.gif'

const AboutUsBetaPartners = () => {
  return (
    <div className='flex flex-col justify-center py-[33px] lg:py-[45px] gap-[20px] lg:gap-[30px]' style={{ boxShadow: '0 0 30px rgba(0, 0, 0, 0.1)' }}>
      <h3 className='text-center font-semibold text-[13px] lg:text-[22px]' style={{ color: 'rgba(0, 0, 0, 0.28)' }}>Our Trusted Partners</h3>
      <img src={BetaPartners} alt='BetaPartners' loading='lazy' className='object-cover w-full h-[35px] lg:h-auto' />
    </div>
  )
}

export default AboutUsBetaPartners
