import clsx from 'clsx'

import upperRightArrow from '../../../../assets/images/upper-right.svg'

export function RmcNewsAndMedia(rmc_news_and_media: schema.Link[]) {
  return (
    <div className='mt-10'>
      <div className='flex justify-between'>
        <h3 className='mb-5 text-lg font-bold leading-[30px] text-black md:text-xl md:leading-[35px]'>
          Related RecruitmentMarketing.com News & Media
        </h3>
      </div>
      <div className={clsx(`flex flex-col py-5 md:py-8`)}>
        <ul className={clsx(`grid grid-cols-1 gap-4 lg:grid-cols-3`)}>
          {Object.values(rmc_news_and_media).map((link: schema.Link) => {
            return (
              <li key={link.id} className='max-h-max min-h-[150px]'>
                <a
                  href={link.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='max-w-sm bg-white rounded-xl overflow-hidden border pb-5'>
                      <div className='h-64 border' style={{ backgroundImage: `url(${link.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                      <div className='px-5 py-3 h-32 overflow-hidden box-border relative'>
                          <div className='absolute right-0 mr-2 p-1'><img src={`${upperRightArrow}`} alt='arrow icon' /></div>
                          <p className='font-bold tracking-tight text-gray-900 dark:text-white mr-1.5'>{link.title}</p>
                          <p className='mb-3 font-normal text-gray-700'>{link.description}</p>
                      </div>
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
