import React, { useState, Suspense, useEffect } from 'react'
import RMCFooterLogo from 'app/assets/images/rmc-footer-logo.png'
import WhiteFacebookIcon from 'app/assets/images/white-facebook-icon.svg'
import WhiteLinkedinIcon from 'app/assets/images/white-linkedin-icon.svg'
import WhiteTwitterIcon from 'app/assets/images/white-twitter-icon.svg'
import { footerNavLinks, SocialLinks } from '@/components/atoms/constants/navLinks'
import Button from '@/components/atoms/buttons/Button'
import gradient from 'app/assets/images/gradient.png'

import HybridLink from '@/components/atoms/widgets/HybridLink'
import PrivacyPreferences from '@/components/atoms/buttons/PrivacyPreferences'
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal'
import useCurrentUser from '@/hooks/useCurrentUser'

const FooterSection = () => {
  const currentUser = useCurrentUser()
  const [footerLinks, setFooterLinks] = useState(footerNavLinks)

  useEffect(()=>{
    const linksToFilter = currentUser ? ['Login'] : []
    if(currentUser?.newsletter_subscribed){
      linksToFilter.push('Sign Up');
    }
    setFooterLinks(footerNavLinks.filter(link => !linksToFilter.includes(link.name)));
  }, [])

  const [openSubscribeModal, setOpenSubscribeModal] = useState(false)
  const handleSignUpTopNav = () => {
    setOpenSubscribeModal(true)
  }

  return (
    <>
      <Suspense fallback={<div></div>}>
        <LeadGenerationModal
          openModal={openSubscribeModal}
          setOpenModal={setOpenSubscribeModal}
          formType={'newsletter'}
          currentUser={currentUser}
        />
      </Suspense>
      <div className='text-center text-white pt-12 bg-cover bg-center' style={{ backgroundImage: `url(${gradient})`}}>
      {(currentUser?.newsletter_subscribed) ? null :
        <div>
          <p className='xl:text-[38.78px] 2xl:text-4xl pt-5 text-[19px] sm:text-2xl sm:pt-14 font-semibold'>Join our community</p>
          <p className='xl:text-[17px] 2xl:text-md text-[8.61px] sm:text-xs sm:py-8 py-4'>Get weekly insights. Make industry connections. Discover new tech.</p>
            <Button
              variant='white'
              className='mx-auto flex h-[28px] w-[103px] sm:h-[40px] sm:w-[140px] items-center justify-center text-[#15127E] py-[4px] text-[7.55px] sm:text-xs xl:text-[14.917px] font-bold'
              style={{borderRadius:'5.82px'}}
              onClick={handleSignUpTopNav}
            >
              Sign Up
            </Button>
        </div>
      }
      <div className='sm:pt-[6rem] pt-14'>
        <p className='font-semibold text-[12px] 2xl:text-2xl xl:text-[23.78px]'>Follow us</p>
          <div className='flex justify-center items-center sm:gap-8 gap-6 pt-6'>
            <div className='flex items-center justify-center border-2 border-white sm:p-3 rounded-full w-[37px] h-[37px] sm:w-[4.6875rem] sm:h-[4.6875rem]'>
              <HybridLink
                href={SocialLinks.linkedin.url}
                isExternal={SocialLinks.linkedin.external}
              >
                <img src={WhiteLinkedinIcon} alt='linkedin icon' className='w-[15px] h-[15px] sm:w-[40px] sm:h-[40px]' />
              </HybridLink>
            </div>
            <div className='flex items-center justify-center border-2 border-white sm:p-3 rounded-full w-[37px] h-[37px] sm:w-[4.6875rem] sm:h-[4.6875rem]'>
              <HybridLink
                href={SocialLinks.facebook.url}
                isExternal={SocialLinks.facebook.external}
              >
                <img src={WhiteFacebookIcon} alt='facebook icon' className='w-[15px] h-[15px] sm:w-[40px] sm:h-[40px]' />
              </HybridLink>
            </div>
            <div className='flex items-center justify-center border-2 border-white sm:p-3 rounded-full w-[37px] h-[37px] sm:w-[4.6875rem] sm:h-[4.6875rem]'>
              <HybridLink
                href={SocialLinks.twitter.url}
                isExternal={SocialLinks.twitter.external}
              >
                <img src={WhiteTwitterIcon} alt='twitter icon' className='w-[15px] h-[15px] sm:w-[40px] sm:h-[40px]' />
              </HybridLink>
            </div>
          </div>
        </div>
        <div className='sm:pt-[133.93px] pt-12'>
          <img src={RMCFooterLogo} alt='rmc logo' className='mx-auto w-[211px] 2xl:w-211 sm:w-[13rem] pb-[43.07px]'/>
          <div className='flex flex-wrap justify-center md:gap-4 sm:gap-2 gap-1 md:pb-[43.07px] pb-2'>
            {
              footerLinks.map((footerLink, index) => (
                <>
                  {
                    footerLink.name === 'Sign Up' ? 
                    <span onClick={() => setOpenSubscribeModal(true)} 
                    className='2xl:text-lg text-3xs sm:text-xs xl:text-[16px] lg:text-[16px] cursor-pointer'>{footerLink.name}</span> :
                    <HybridLink
                      key={index}
                      href={footerLink.url}
                      isExternal={footerLink.external}
                      className='2xl:text-lg text-3xs sm:text-xs xl:text-[16px] lg:text-[16px]'
                    >
                      {footerLink.name}
                    </HybridLink>
                  }
                  <div className='2xl:text-xl md:text-sm text-3xs'>
                    {index === footerLinks.length ? null : '|'}
                  </div>
                </>
              ))
            }
            <PrivacyPreferences className='2xl:text-lg text-3xs sm:text-xs xl:text-[16px] lg:text-[16px] cursor-pointer'/>
          </div>
          <p className='2xl:text-md text-3xs sm:text-xs pb-10'>© Copyright 2023 Recruitment Marketing LLC • All Rights Reserved</p>
        </div>
      </div>
    </>
  )
}

export default FooterSection
