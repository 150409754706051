import HorizontalMarginsWrapper from '@/components/atoms/wrappers/HorizontalMarginsWrapper'
import BuyerGuide from '@/components/molecules/Category/BuyerGuide'
import NavbarSearch from '@/components/molecules/navbar/NavbarSearch'
import CategoryHeroSection from '@/components/molecules/category/CategoryHeroSection'
import Navbar from '@/components/organisms/layouts/Navbar'
import { Popover } from '@headlessui/react'
import React, { useEffect, useState, useRef } from 'react'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import { useMobileDetect } from '@/utils/detectMobile'
import SolutionTicker from '@/components/organisms/Marketpalce/SolutionTicker'
import pocketShadow from '../../../assets/images/pocketshadow 1.svg'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import { FaFilter } from 'react-icons/fa6'
import { GoChevronRight } from "react-icons/go"
import { GoChevronLeft } from "react-icons/go"
import window from 'global'

import {
  sortOptionsMap,
  sortOptionsReverseMap,
} from '@components/atoms/constants/solutionOptions'
import Modal from '@components/atoms/modals/Modal'
import CategorySolutionCard from '@/components/molecules/categoryPage/CategorySolutionCard'
import Chevron from 'app/assets/images/chevron-left.svg'
import axios from 'axios'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import ReactPaginate from 'react-paginate'
import SearchedSolutionsAndCategories from '../Solutions/SearchedSolutionsAndCategories'
import Button from '@/components/atoms/buttons/Button'
import { CompareSolutionTab } from '@/components/molecules/compareSolution/CompareSolutionTab'
import FilterSidebar from '@/components/atoms/filters/FilterSidebar'
import useLocalStorage from '@/components/atoms/hooks/useLocalStorage'
import Input from '@/components/atoms/inputs/Input'
import { SortMenu } from '@/components/molecules/filterAndSort/SortMenu'
import HandleSearch from '@/utils/HandleSearch'
import { mixpanel } from '@/utils/mixpanel'
import IntegrationsDropdown from '@/components/molecules/categoryPage/IntegrationsDropdown'
import FeaturesDropdown from '@/components/molecules/categoryPage/FeaturesDropdown'
import HasPricingDropdown from '@/components/molecules/categoryPage/HasPricingDropdown'
import { RatingFilter } from '@/components/molecules/filterAndSort/RatingFilter'
import Meta from '@/components/atoms/widgets/Meta'
import LearnMoreSection from '@/components/molecules/Category/LearnMoreSection'
import useComparedSolutions from '@/hooks/useComparedSolutions'

type CategoryShowProps = {
  category: schema.Category,
  parent_category: string | null,
  categorySolutions: schema.Solution,
  integrations: string[] | null,
  features: string[] | null,
  featured_solutions: schema.Solution[]
  media_details: {
    media_url: string
    media_type: string
    file_name: string
  },
  learnMoreLinks: []
  hideLearnMore: boolean
}

const CategoryShow = ({
  category, 
  parent_category, 
  categorySolutions, 
  media_details, 
  integrations, 
  featured_solutions,
  learnMoreLinks,
  hideLearnMore,
  features
 }: CategoryShowProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [searchQuery, setSearchQuery] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [solutionsToCompare, setSolutionsToCompare] = useComparedSolutions()
  const [enterPressed, setEnterPressed] = useState(false)
  const [filter, setFilter] = useLocalStorage('categories-filters', {
    claimed: false,
    sort: 'is_featured',
    rating_gteq: null
  })
  const [searchResultsOpen, setSearchResultsOpen] = useState(false)
  const [solutionsToShow, setSolutionsToShow] = useState([])
  const [searchedSolutions, setSearchedSolutions] = useState([])
  const [currentPagination, setcurrentPagination] = useState([])
  const initialCategoryFilters = JSON.parse(localStorage.getItem('categories-filters'))
  const [selectedIntegrations, setSelectedIntegrations] = useState(initialCategoryFilters?.selectedIntegrations || [])
  const [selectedFeatures, setSelectedFeatures] = useState(initialCategoryFilters?.selectedFeatures || [])
  const [hasPricing, setHasPricing] = useState(initialCategoryFilters?.hasPricing)
  const [page, setPage] = useState(1)
  const isInitialRender = useRef(true)
  const targetRef = useRef(null)

  const scrollToComponent = () => {
    const navbarHeight = document.querySelector('#navbar').offsetHeight;
    const top = targetRef.current.getBoundingClientRect().top + scrollY - navbarHeight;
    window.scrollTo({ top, behavior: 'smooth' });
  };

  const handleReload = async () => {
    const requestData = {
      q: {
        name_i_cont: '',
        claims_status_matches_any: filter?.claimed ? 'approved' : '',
        rating_gteq: filter?.rating_gteq || null,
        searchQuery: searchQuery || null,
      },
      order_by: filter?.sort,
      integrations: selectedIntegrations,
      features: selectedFeatures,
      pricing: hasPricing
    }

    if (page !== 1) {
      Object.assign(requestData, { page: page })
    }

    const response = await axios.get(
      `/categories/${category.id}/filter_category_solutions`,
      {
        headers: {
          Accept: 'application/json',
        },
        params: requestData,
      }
    )

    setSolutionsToShow(response?.data?.solutions)
    setcurrentPagination(response.data.pagination)
    setPage(response.data.pagination.current_page)
  }

  const prevSearchQuery = useRef()
  const handlePopState = () => setSearchQuery('')
  const handleBeforeUnload = () => setSearchQuery('')

  useEffect(() => {
    let filters = localStorage.getItem('categories-filters')

    if (filters) {
      filters = JSON.parse(filters)

      filters.hasPricing = hasPricing
      filters.selectedIntegrations = selectedIntegrations
      filters.selectedFeatures = selectedFeatures

      const updatedFilters = JSON.stringify(filters)
      localStorage.setItem('categories-filters', updatedFilters)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopState)

    const debouncedReload = debounce(() => {
      handleReload()
    }, 300)

    if (prevSearchQuery.current !== searchQuery) {
      if (searchQuery.length >= 2 || searchQuery.length === 0) {
        debouncedReload()
      }
    } else {
      handleReload()
    }

    prevSearchQuery.current = searchQuery

    return () => {
      window.removeEventListener('popstate', handlePopState)
      window.removeEventListener('beforeunload', handleBeforeUnload)

      debouncedReload.cancel()
    }
  }, [filter, enterPressed ? searchQuery : null, enterPressed, selectedIntegrations, selectedFeatures, hasPricing])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      handleReload()
    }
  }, [page])

  useEffect(() => {
    const timer = setTimeout(() => {
      const dropdownHeadings = document.querySelectorAll('.dropdown-heading')
      const dropdownContainers = document.querySelectorAll('.dropdown-container')

      dropdownHeadings.forEach(element => {
        element.classList.remove('dropdown-heading')
        element.classList.add('dropdown-h')
      })

      dropdownContainers.forEach(element => {
        element.style.setProperty('border', 'none', 'important')
        element.style.setProperty('border-radius', '4px', 'important')
      })
    }, 30)

    return () => clearTimeout(timer)
  }, [openModal])

  const handleMixpanelSearchEvent = () => {
    mixpanel.track('Search', {
      'Search Term': searchQuery,
      Category: [category?.name],
      Claimed: filter?.claimed,
      'Sort By': sortOptionsReverseMap[filter?.sort],
      'Entry Point': 'Category Search',
    })
  }

  const handleChange = (e) => {
    if (e.target.value.trim() === '') {
      setSearchResultsOpen(false)
      setSearchedSolutions([])
      setSearchQuery('')
      setEnterPressed(false)
    } else {
      setSearchResultsOpen(true)
      HandleSearch({
        event: e,
        allSolutions: categorySolutions,
        allCategories: [],
        searchTerm: searchQuery,
        setSearchTerm: setSearchQuery,
        setSolutionSearchResults: setSearchedSolutions,
        setCategorySearchResults: null,
      })
      setSearchResultsOpen(searchQuery !== '')
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setEnterPressed(true)
  }

  const isMobile = useMobileDetect()
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <PageTitle title={`Find ${category?.name} Tools on RecruitmentMarketing.com`} />
      <Meta
        title={`Find ${category?.name} Tools on RecruitmentMarketing.com`}
        description={`Discover top recruitment marketing solutions in ${category?.name} on RecruitmentMarketing.com.`}
      />
      <div
        className={`flex w-full flex-col ${windowWidth < 1700 ? 'bg-cover bg-origin-content' : 'bg-contain  bg-no-repeat'} bg-center h-auto pt-[52px] sm:pt-[78px] object-scale-down`}
      >
        <Popover className='flex flex-col z-50'>
          {({ open }) => <Navbar open={open} />}
        </Popover>
        {
          isMobile &&
          <div className='my-10'>
            <NavbarSearch />
          </div>
        }
      </div>
      <div className='bg-[linear-gradient(170.84deg,#3FC8A9_4.13%,#2679A5_110.72%)]'>
        <HorizontalMarginsWrapper>
          <CategoryHeroSection
            category={category}
            parent_category={parent_category}
            scrollToComponent={scrollToComponent}
          />
        </HorizontalMarginsWrapper>
      </div>
      {!category.hide_buyers_guide && (
        <HorizontalMarginsWrapper>
          <BuyerGuide category={category} mediaDetails={media_details}/>
        </HorizontalMarginsWrapper>
      )}
      {  
        featured_solutions?.length > 0 && 
        <>
          <img src={pocketShadow} alt="shadow-effect" className='mb-8'/>
          <HorizontalMarginsWrapper>
            <div>
              <h2 className='text-[28px] lg:text-[38px] text-center font-bold mb-8'>Solutions Spotlight</h2>
              <SolutionTicker solutions={featured_solutions} categoryPageCarousel={true}/>
            </div>
          </HorizontalMarginsWrapper>
          <img src={pocketShadow} alt="shadow-effect" className='my-8'/>
        </>
      }
      <div ref={targetRef} className='mb-10'>
        <HorizontalMarginsWrapper>
          <div className='flex flex-col gap-3 px-5 pt-5'>
            <p>
              <h2 className='inline-block text-[28px] sm:text-[38px] font-extrabold'>Explore Solutions</h2>
              <span className='text-[14px] text-gray-600'> ({currentPagination?.total_count})</span>
            </p>

            <div className='flex justify-between max-h-[30px]'>
              <div className='flex gap-2'>
                <div>
                  <div>
                    <form
                      onSubmit={(e) => {
                        handleSubmit(e)
                      }}
                    >
                      <Input
                        type='text'
                        placeholder='Search by solution name'
                        search={true}
                        value={searchQuery}
                        onChange={(e) => handleChange(e)}
                        searchIconClasses=' !left-[5.5px] !bottom-[3px] !mb-[0px] !size-4'
                        className='text-[14px] !pl-[26px] !py-[3px] sm:!py-[0px] !rounded-[4px] !pr-5'
                      >
                        {searchedSolutions.length > 0 &&
                          searchResultsOpen &&
                          !enterPressed && (
                            <SearchedSolutionsAndCategories
                              wrapperClassName={clsx(
                                'relative left-0 top-[0px] z-50',
                                solutionsToShow.length > 5 && 'max-h-[250px]'
                              )}
                              searchedSolutions={searchedSolutions}
                              searchedCategories={[]}
                              searchTerm={searchQuery}
                              onClose={() => {
                                setSearchResultsOpen(false)
                              }}
                              open={searchResultsOpen}
                            />
                          )}
                      </Input>
                    </form>
                  </div>
                </div>
                <div>
                  <div className='hidden md-xl:flex gap-2'>
                    <RatingFilter 
                      initialValue={filter.rating_gteq}
                      handleChange = {(value: number) =>{
                        setFilter({
                          ...filter,
                          rating_gteq: value,
                        })}
                      }
                    />
                    <HasPricingDropdown
                      hasPricing={hasPricing}
                      setHasPricing={setHasPricing}
                    />
                    { integrations.length > 0 &&
                      <IntegrationsDropdown
                        integrations={integrations}
                        selectedIntegrations={selectedIntegrations}
                        setSelectedIntegrations={setSelectedIntegrations}
                      />
                    }
                    <FeaturesDropdown
                      features={features}
                      selectedFeatures={selectedFeatures}
                      setSelectedFeatures={setSelectedFeatures}
                    />
                  </div>
                  <div
                    className='hidden sm:flex items-center gap-2 bg-dark-gray rounded-[4px] sm:py-[2.5px] sm:px-2 md-xl:hidden'
                    onClick={() => {
                      setOpenModal(true)
                    }}
                  >
                    <p className='hidden sm:flex text-[14px] text-gray-600'>Filters</p>
                    <FaFilter className='text-gray-600' />
                  </div>
                </div>
              </div>
              <div className='hidden items-center gap-4 sm:flex'>
                <SortMenu
                  options={Object.entries(sortOptionsMap).map(([label, value]) => ({
                    label,
                    value,
                  }))}
                  onChange={(value) => {
                    setFilter({
                      ...filter,
                      sort: value,
                    })
                  }}
                  selected={filter.sort as string}
                />
              </div>
              <div
                className='flex bg-dark-gray rounded-[4px] p-2 sm:hidden'
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                <FaFilter className='text-gray-600' />
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              {solutionsToShow?.length > 0 && (
                <div
                  className='grid grid-cols-1 gap-6'
                  onClick={() => handleMixpanelSearchEvent()}
                >
                  {solutionsToShow.map(
                    (solution) => (
                      <CategorySolutionCard
                        solution={solution}
                        key={solution.name}
                        solutionsToCompare={solutionsToCompare}
                        setSolutionsToCompare={setSolutionsToCompare}
                      />
                    )
                  )}
                </div>
              )}
              {solutionsToShow?.length > 0 && (
                <div className='flex justify-between'>
                  <span className="text-gray-600">
                    Showing {solutionsToShow.length} of {categorySolutions.length}
                  </span>
                  <ReactPaginate
                    pageCount={currentPagination?.total_pages}
                    pageRangeDisplayed={6}
                    marginPagesDisplayed={1}
                    previousLabel={<GoChevronLeft className="text-gray-600"/>}
                    nextLabel={<GoChevronRight className="text-gray-600" />}
                    breakLabel='...'
                    onPageChange={(event) => {
                      setPage(event.selected + 1)
                    }}
                    forcePage={page - 1}
                    containerClassName='flex justify-between items-center self-stretch'
                    pageClassName='flex px-1 text-gray-600'
                    activeClassName='text-[#2679A5] text-lg font-bold'          
                  />
                </div>
              )}
            </div>
            <Modal
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              className='mx-4 max-w-[600px] rounded-xl bg-white p-10'
            >
              <div className='max-h-screen overflow-y-auto rounded-t-3xl bg-white px-4 py-8'>
                <h3 className='mb-7 text-2xl font-bold leading-tight'>
                  Filters and Sort
                </h3>
                <div className='flex flex-col gap-2'>
                  <RatingFilter 
                      initialValue={filter.rating_gteq}
                      handleChange = {(value: number) =>{
                        setFilter({
                          ...filter,
                          rating_gteq: value,
                        })}
                      }
                    />
                  <div className='flex flex-col sm:hidden'>
                    <SortMenu
                      options={Object.entries(sortOptionsMap).map(([label, value]) => ({
                        label,
                        value,
                      }))}
                      onChange={(value) => {
                        setFilter({
                          ...filter,
                          sort: value,
                        })
                      }}
                      selected={filter.sort as string}
                    />
                  </div>
                  <IntegrationsDropdown
                    integrations={integrations}
                    selectedIntegrations={selectedIntegrations}
                    setSelectedIntegrations={setSelectedIntegrations}
                  />
                  <HasPricingDropdown
                    hasPricing={hasPricing}
                    setHasPricing={setHasPricing}
                  />
                  <FeaturesDropdown
                    features={features}
                    selectedFeatures={selectedFeatures}
                    setSelectedFeatures={setSelectedFeatures}
                  />
                </div>
                <div className='mt-4 flex flex-col gap-4' id='filterSortActions'>
                  <Button
                    size='md'
                    variant='primary'
                    onClick={() => setOpenModal(false)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </HorizontalMarginsWrapper>
      </div>
      { 
        (!hideLearnMore && learnMoreLinks?.length > 0) && (
          <HorizontalMarginsWrapper>
            <LearnMoreSection 
              links={learnMoreLinks} 
              categoryName={category?.name} 
            />
          </HorizontalMarginsWrapper>
        )
      }
      {solutionsToCompare?.length > 0 && (
        <div className='sticky bottom-0 z-[10] bg-[#ECEDF1] shadow-sticky-bar'>
          <CompareSolutionTab
            solutionsToCompare={solutionsToCompare}
            setSolutionsToCompare={setSolutionsToCompare}
          />
        </div>
      )}
      <FooterSection />
    </>
  )
}

export default CategoryShow
