import MarketplaceCard from '@/components/atoms/marketplaceRow/marketplaceCard';
import RmcLogo from '../../../../assets/images/rmc-logo.png';

const MarketplaceRow = () => {
  return (
    <div className='relative text-clip rounded-xl bg-blue-tint-2 bg-opacity-[0.5] p-8 border border-blue-tint-2'>
      <div className='flex xl:flex-row flex-col'>
        <div className='sm:flex sm:flex-row flex flex-row-reverse xl:justify-center xl:items-center'>
          <img src={RmcLogo} className='w-[48px] h-[48px]' alt='RMC Logo' />
          <div className='flex-col lg:pl-2'>
            <p className='text-xl font-bold text-dark-blue'>
              We're not like other marketplaces.
            </p>
            <p className='text-xs font-medium text-dark-blue'>
              We deliver talent acquisition leaders with real, buying intent.
            </p>
          </div>
        </div>
        <div className='inline-grid xl:grid-cols-3 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-7 lg:pl-3 xl:pt-0 pt-5'>
          <MarketplaceCard countFigure={'1'} Description={'Type of Buyer'} />
          <MarketplaceCard countFigure={'1K+'} Description={'Marketplace Solutions'} />
          <div className='col-span-2 sm:col-span-1 flex flex-col justify-center items-center lg:w-[185px] h-[88px] bg-white rounded-xl border border-blue-tint-2 p-5'>
            <p className='text-xl font-bold text-dark-blue'>10K+</p>
            <p className='text-xs text-center text-dark-blue'>
              Quality Visitors per Month
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceRow;
