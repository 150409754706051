import { useState, useEffect, useRef, RefObject } from 'react'

import { router, usePage } from '@inertiajs/react'
import axios from 'axios'
import clsx from 'clsx'

import { useToken } from '@components/atoms/forms/Form'
import { mixpanel } from '@/utils/mixpanel'
import { handleSignUp } from '@/utils/SignUpEventTracking'
import { useTracking } from 'react-tracking'
import window from 'global'

import { DesktopNav, MobileNav, MobilePopoverPanel } from '@/components/molecules'
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal'
import { getAllParentChildCategories } from '@/utils/axiosRequests'

type NavbarProps = {
  open: boolean
  hideDropdown?: boolean
  setHideDropdown?: React.Dispatch<React.SetStateAction<boolean>>
  handleCategoriesDropdown: () => void
  dropdownRef: RefObject<HTMLInputElement>
  showCategoriesDropdown: boolean
  funnelCategories: React.Dispatch<React.SetStateAction<[]>>
  toggleRef: RefObject<HTMLInputElement>
}

export default function Navbar({ open, hideDropdown, setHideDropdown }: NavbarProps) {
  const { props } = usePage()
  const token = useToken()

  const rootUrl = props?.root_url || '/'
  const rootUrlExternal = props?.root_url_external || false
  const [currentUser, setCurrentUser] = useState(props?.currentUser || null)

  const [openSubscribeModal, setOpenSubscribeModal] = useState(false)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1280)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false)
  const [funnelCategories, setFunnelCategories] = useState([])

  const tracking = useTracking()
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllParentChildCategories(token);
        setFunnelCategories(response.data)
      } catch (err) {
        console.log('Error Fetching Categories...')
      }
    };

    fetchCategories();
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef.current.contains(event.target) &&
        !toggleRef.current?.contains(event.target)
      ) {
        setShowCategoriesDropdown(false);
      }
    };

    if (showCategoriesDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCategoriesDropdown]);

  const handleCategoriesDropdown = () => {
    setShowCategoriesDropdown((prev) => !prev);
    setHideDropdown(false)
  }

  useEffect(() => {
    if (hideDropdown) {
      setShowCategoriesDropdown(false);
    }
  }, [hideDropdown])

  const handleLogout = async () => {
    if (currentUser) {
      try {
        const response = await axios.delete('/users/sign_out', {
          headers: {
            'X-CSRF-Token': token,
          },
        })
        if (response.status === 204) {
          tracking.trackEvent({
            event_name: 'session_end',
            page_url: window.location.pathname,
            event_loggable_type: "RmcEvents::RmcEvent",
            additional_attributes: { event_type: 'session_end' }
          })
          localStorage.setItem('rmc_logged_in', 'false')
          localStorage.setItem('rmc_logged_in_user_id', '')
          window.location.href = '/login'
          mixpanel.cookie.clear()
          setCurrentUser(null)
        }
      } catch (error) {
        // handle log out error
      }
    } else {
      router.visit('/login')
    }
  }

  const handleSignUpTopNav = () => {
    setOpenSubscribeModal(true)
    handleSignUp('Button_SignUp_TopNav', 'Sign Up')
  }

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1280)
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div id='navbar' className='bg-white w-full fixed top-0 z-30 border-b-2 border-cyan'>
      <div
        className={clsx(
          `mx-auto w-[329px] sm:w-full font-manrope flex h-[52px] sm:px-8
           sm:py-4 items-center justify-between sm:h-[78px] md:px-[4rem]
           xl:px-[9rem] lg:px-40 max-w-[1254px] xl:mx-auto`
        )}
      >
        {isDesktop ?
          <DesktopNav
            currentUser={currentUser}
            rootUrl={rootUrl}
            handleSignUpTopNav={() => handleSignUpTopNav()}
            handleLogout={() => handleLogout()}
            hideDropdown={hideDropdown}
            setHideDropdown={setHideDropdown}
            handleCategoriesDropdown={handleCategoriesDropdown}
            dropdownRef={dropdownRef}
            showCategoriesDropdown={showCategoriesDropdown}
            setShowCategoriesDropdown={setShowCategoriesDropdown}
            funnelCategories={funnelCategories}
            toggleRef={toggleRef}
          />
          :
          <>
            <MobileNav
              open={open}
              rootUrl={rootUrl}
              rootUrlExternal={rootUrlExternal}
              currentUser={currentUser}
              handleLogout={() => handleLogout()}
              handleSignUpTopNav={() => handleSignUpTopNav()}
              isMobile={isMobile}
              hideDropdown={hideDropdown}
              setHideDropdown={setHideDropdown}
              showCategoriesDropdown={showCategoriesDropdown}
              setShowCategoriesDropdown={setShowCategoriesDropdown}
            />
          </>
        }
      </div>
      {!isDesktop && (
        <MobilePopoverPanel
          currentUser={currentUser}
          handleLogout={() => handleLogout()}
          handleSignUpTopNav={() => handleSignUpTopNav()}
          tracking={tracking}
          hideDropdown={hideDropdown}
          handleCategoriesDropdown={handleCategoriesDropdown}
          dropdownRef={dropdownRef}
          showCategoriesDropdown={showCategoriesDropdown}
          setShowCategoriesDropdown={setShowCategoriesDropdown}
          funnelCategories={funnelCategories}
        />
      )}
      <LeadGenerationModal
        openModal={openSubscribeModal}
        setOpenModal={setOpenSubscribeModal}
        formType={'newsletter'}
      />
    </div>
  )
}
