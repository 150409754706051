import React from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

type ReCaptchaProps = {
  setRecaptchaToken: (value: string) => void
}

const ReCaptcha: ReCaptchaProps = ({ setRecaptchaToken }) => {
  const handleVerify = (response) => {
    if (response) {
      setRecaptchaToken(response)
    }
  }

  return (
    <div>
      <ReCAPTCHA
        sitekey='6Ldt5TooAAAAABDpbucpYShmudBqw-mbPC-MY97M'
        onChange={handleVerify}
      />
    </div>
  )
}

export default ReCaptcha
