import { ParentCategories } from '../../../../utils/constants'
import funnelEffect from '../../../../../assets/images/funnel-effect.png'
import { useMemo } from 'react'
import { useMobileDetect } from '@/utils/detectMobile'

type FunnelProps = {
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
}

export const Funnel = ({
  activeIndex,
  setActiveIndex,
}: FunnelProps) => {

  const isMobile = useMobileDetect({screenSize: 1024})

  const INIT_WIDTH = isMobile ? 250 : 300
  const FUNNEL_WIDTH_STEP = 10
  const FUNNEL_BG_STEP = 70

  const calculateFunnelWidth = (index) => {
    return INIT_WIDTH - (index * FUNNEL_WIDTH_STEP)
  }

  return (
    <div className='flex flex-col items-center text-white w-full md:w-[50%] py-4'>
      { ParentCategories.map((category, index) => {
        const dynamicWidth = useMemo(() => calculateFunnelWidth(index), [INIT_WIDTH, FUNNEL_WIDTH_STEP])

        return (
          <div
            key={index}
            className={`flex flex-col sm:px-[2rem] lg:px-auto items-center justify-center w-full md:border-solid md:border-l-2 ${activeIndex === index  && 'md:!border-l-[4px] md:!border-darker-gray'}`}
          >
            <div
              className={`bg-light-teal shadow-lg text-center py-2 lg:py-3 rounded-[25px] cursor-pointer
                ${index == 0 ? 'rounded-t-none' : ''}
                ${index == ParentCategories.length - 1 ? 'rounded-b-none' : ''}
              `}
              style={{ 
                width: `${dynamicWidth}px`,
                background: `${activeIndex === index  ? 'linear-gradient(90deg, #3FC8A9 5.74%, #2981A6 48.74%, #3CC4A8 97.3%)' : ''}`
              }}
              onClick={() => setActiveIndex(index)}
            >
              <p className='lg:text-[20px] text-center font-bold'>{category.title}</p>
            </div>
            { index != ParentCategories.length - 1 && 
              <img 
                src={funnelEffect}
                alt='funnel-effect'
                style={{ width: `${dynamicWidth - FUNNEL_BG_STEP}px` }}
                className='h-2'
              />
            }
          </div>
        )
      })}
    </div>
  )
}