import { servicesHeroCards } from '@/components/atoms/constants/OurServicesData'

function ServicesHero() {
  return (
    <div className="flex font-bold md:max-h-[122px] md:min-h-[122px] lg:max-h-[175px] lg:min-h-[175px] rounded-lg shadow-service-hero items-center justify-center relative md:top-[61px] lg:top-[87.5px] bg-white">
      {servicesHeroCards.map((card, index) => (
        <div key={index} className="flex text-center items-center justify-center">
          <div className="item-center md:mx-[2.8rem] lg:mx-[2rem] xl:mx-[4.4rem]">
            <p className="font-semibold md:text-[9.76px] lg:text-[14px]">{card.title}</p>
            <h1 className="font-extrabold text-black md:text-[33.463px] md:tracking-[-0.976px] md:leading-[37px] lg:text-[48px] lg:leading-[52px] lg:tracking-[-1.399px]">
              {card.value}
            </h1>
            <p className="font-semibold md:text-[9.76px] md:leading-[11.85px] lg:text-[14px] lg:leading-[17px]">{card.description}</p>
          </div>
          {index !== servicesHeroCards.length - 1 && <div className="w-[1px] md:h-[63px] lg:h-[89px] bg-[#41CDA7] self-center"></div>}
        </div>
      ))}
    </div>
  );
}

export default ServicesHero
