import { useState, useEffect, lazy, Suspense } from 'react'
import ServicesCards from './ServicesCards'
import ServicesCardInsights from './ServicesCardInsights'
import ServicesHero from './ServicesHero'
const LazyServicesMobileComponent = lazy(() => import('./ServicesMobileComponent'))

function OurServicesSection() {
  const [activeCardId, setActiveCardId] = useState(1)
  const [isMediumScreen, setIsMediumScreen] = useState(false)
  const [isLargeScreen, setIsLargeScreen] = useState(false)

  const handleResize = () => {
    setIsMediumScreen(window.innerWidth <= 768)
    setIsLargeScreen(window.innerWidth > 768)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [window.innerWidth])

  return (
    <div className="mt-[25px] md:mt-[45px] lg:mt-[78px]">
      <h1 className="text-center font-bold text-[24px] sm:text-[30px] mb-[10px]">
        Our Services
      </h1>
      <p className="mx-auto w-[287px] md:w-[351px] lg:w-[497px] text-center font-semibold text-[11px] md:text-[13.38px] lg:text-[19px] mb-[46px] md:mb-[50px] lg:mb-[96px]">
        Stay informed, engage top talent, and find the right tech solutions for every stage of the hiring funnel.
      </p>
      {isLargeScreen && (
        <div>
          <ServicesCards setActiveCardId={setActiveCardId} activeCardId={activeCardId} />
          <ServicesCardInsights activeCardId={activeCardId} />
          <ServicesHero />
        </div>
      )}
      <Suspense fallback={<div></div>}>
        {isMediumScreen && (
          <div>
            <LazyServicesMobileComponent />
          </div>
        )}
      </Suspense>
    </div>
  )
}

export default OurServicesSection
