import { useState, useRef } from 'react'

import AvatarEditor from 'react-avatar-editor'

import { useMobileDetect } from '@/utils/detectMobile'

type ImageCropComponentProps = {
  src: string
  setCroppedFile: (file: File) => void
}

const ImageCropComponent = ({ src, setCroppedFile } : ImageCropComponentProps) => {
  const [img, setImg] = useState(null) 
  const editorRef = useRef(null)
  const isMobile = useMobileDetect({ screenSize: 640 })

  const SaveCrop = () => {
    if (editorRef.current) {
      setImg(editorRef.current.getImageScaledToCanvas().toDataURL())
      handleCroppedImage(editorRef.current.getImageScaledToCanvas())
    }
  }

  const handleCroppedImage = (canvas) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        return
      }
      const file = new File([blob], 'croppedImage.png', { type: 'image/png' })
      setCroppedFile(file)
    }, 'image/png', 1)
  }

  return (
    <div>
      <AvatarEditor
        ref={editorRef}
        image={src}
        height={isMobile? 90 : 180}
        width={window.innerWidth}
        border={[0,40]}
        color={[0, 0, 0, 0.5]}
        scale={1}
        rotate={0}
        style={{ width: '100%' }}
        className='mr-[20px] bg-cover bg-center'
        onImageChange={SaveCrop}
        onImageReady={SaveCrop}
        borderRadius={0}
      />
      <h1 className='text-md font-semibold text-gray-500'>Preview</h1>
      <img src={img} className='[h-90px] sm:h-[180px]!max-h-[90px] !sm:max-h-[180px] mt-2 w-full bg-cover bg-center' alt='Cropped preview' />
    </div>
  )
}

export default ImageCropComponent
