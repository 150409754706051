import { PromoCards } from '@/utils/constants'

const PromoMobileHeader = () => {
  return (
    <div className='flex flex-col gap-8 my-12'>
      {PromoCards.map((card, index) => (
        <div key={index} className='flex flex-col h-full self-center w-full bg-white rounded-xl min-w-[323px] max-w-[323px]  
                min-h-[182px] max-h-[182px] justify-center items-center shadow-service-hero'>
          <p className='text-center font-extrabold text-[48px] tracking-[-2.4px]'>{card.value}</p>
          <p className='text-center font-extrabold text-[16px] leading-[17px]'>{card.description}</p>
        </div>
      ))}
    </div>
  )
}

export default PromoMobileHeader
