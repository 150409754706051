/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import Button from '../../atoms/buttons/Button';
import { ImCross } from "react-icons/im";
import { handleSignUp } from '@/utils/SignUpEventTracking';
import LeadGenerationModal from './LeadGenerationModal'

const VisitorPopupModal = ( {currentUser} ) => {

  const [openNewsletterForm, setOpenNewsletterForm] = useState(false)
  const [openVisitorPopup, setOpenVisitorPopup] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => { !(currentUser?.newsletter_subscribed) && setOpenVisitorPopup(true) }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleSignUpClick = () => {
    setOpenNewsletterForm(true)
    handleSignUp('Button_SignUp_Popup', 'Sign Up');
  };

  return (
    ( openVisitorPopup ) && (
      <div className="fixed bottom-1 z-10 m-1">
        {!openNewsletterForm && (
          <div className="flex w-[325px] flex-col gap-5 rounded-xl bg-dark-blue p-4">
            <div className="flex items-start">
              <p className="text-[22px] font-semibold text-white">
                Industry news delivered right to your inbox.
              </p>
              <ImCross
                onClick={() => setOpenVisitorPopup(false)}
                className='text-white text-3xl'
              />
            </div>
            <p className="text-[16px] text-white">
              A weekly newsletter written by talent pros, for talent pros—equipping you with all you need to know to stay ahead in the world of talent acquisition.
            </p>
            <div className="flex justify-center">
              <Button
                variant="primary"
                onClick={handleSignUpClick}
                className="h-[50px] w-[200px]"
              >
                Sign Up
              </Button>
            </div>
          </div>
        )}
        <LeadGenerationModal
          openModal={openNewsletterForm}
          setOpenModal={setOpenNewsletterForm}
          formType={'newsletter'}
          currentUser={currentUser}
        />
      </div>
    )
  );
};

export default VisitorPopupModal;
