import IntroSectionImage from '../../../../assets/images/introsection.png';
import { Link } from '@inertiajs/react';

const IntroSection = () => {
  return (
    <>
      <div className="max-w-[1254px] xl:mx-auto flex flex-col md:flex-row sm:px-4 md:px-10 xl:px-46 lg:px-36 xl:bg-gray-100 xl:mx-[2.188rem] mx-0">
        <div className="flex-1 sm:px-0 xl:pr-16 lg:pr-10 lg:text-left md:pr-6 md:text-left text-center order-2 md:order-1 sm:order-2">
          <h1 className='md:px-0 w-[275px] mx-auto leading-normal -tracking-[0.5px] sm:w-full md:mt-[30px] mt-[38px] sm:text-[1.75rem] xl:text-[34px] text-2xl font-extrabold md:leading-[35px] lg:leading-[42px] xl:leading-[49.3px] xl:tracking-[0.5px]'>A community of talent pros, helping talent pros.</h1>
          <p className='w-[275px] sm:w-auto mx-auto mt-[27px] sm:mt-5 xl:text-[0.875rem] lg:text-xs md:text-xs sm:text-md text-[11px] xl:font-normal xl:leading-[28px]'>At RecruitmentMarketing.com, we're all about helping you do your job better. As the leading one-stop resource for talent acquisition professionals, we feature a <strong>daily news publication</strong>, a <strong>weekly newsletter</strong>, and a <strong>software marketplace</strong> dedicated to empowering talent pros navigating the recruitment marketing landscape.</p>
        </div>
        <div className='flex flex-col justify-end md:my-auto xl:w-[450px] xl:h-[350px] md:w-[320px] sm:px-0 relative order-1 md:order-2 sm:order-1'>
          <div className='mt-[49px] mb-[37px] md:mt-0 mx-auto'>
            <Link href='/marketplace'>
              <img src={IntroSectionImage} alt="intro image" loading='lazy' rel="nofollow" />
            </Link>
          </div>
          <div>
          </div>
        </div>
      </div>
      <div className="hidden xl:mx-auto xl:block lg:block md:block md:px-10 xl:px-46 lg:px-36 xl:mx-[2.188rem] lg:mt-[1rem]">
        <div className="shadow-lg max-w-[964px] mx-auto relative top-[90px] hidden sm:block xl:mt-[-60px] lg:mt-[-60px] bg-white rounded-md">
          <div className="flex justify-evenly xl:py-[2rem] py-[0.5rem] xl:flex-row lg:flex-row items-center">
            <div className="lg:px-10 md:px-8 text-center">
              <p className='text-[14px] font-normal'>Join</p>
              <h1 className='xl:text-[48px] md:text-[28px] text-black font-extrabold -tracking-[1.399px]'>100K+</h1>
              <p className='xl:text-[14px] text-2xs font-normal leading-tight'>talent professionals <br /> using our platform.</p>
            </div>
            <div className="h-[6.8rem] my-2.5 w-[1.021px] bg-[#41CDA7] mx-4"></div>
            <div className="lg:px-10 md:px-8 text-center">
              <p className='text-[14px] font-normal'>Discover</p>
              <h1 className='xl:text-[48px] md:text-[28px] text-black font-extrabold -tracking-[1.399px]'>1,300+</h1>
              <p className='xl:text-[14px] text-2xs font-normal leading-tight'>talent solutions, peer-reviewed <br /> and listed on our Marketplace.</p>
            </div>
            <div className="h-[6.8rem] my-2.5 w-[1.021px] bg-[#41CDA7] mx-4"></div>
            <div className="lg:px-10 md:px-8 text-center">
              <p className='text-[14px] font-normal'>Explore</p>
              <h1 className='xl:text-[48px] md:text-[28px] text-black font-extrabold -tracking-[1.399px]'>500+</h1>
              <p className='xl:text-[14px] text-2xs font-normal leading-tight'>industry conferences <br /> and events.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Intro Bottom Section */}
      <div className="mt-[45px] xl:hidden lg:hidden md:hidden flex flex-col items-center">
        <div className="border-solid border-2 rounded-[0.6rem] border-l-[#43D1A8] border-t-[#43D1A8] border-r-[#329DA6] border-b-[#329DA6] w-[221px] h-[125px] sm:w-56 sm:h-32 sm:mb-4 p-4 text-center mb-[15px]">
          <p className='text-[9px] sm:text-xs'>Join</p>
          <h1 className='text-4xl text-black font-extrabold -tracking-[0.877px]'>100K+</h1>
          <p className='text-[9px] sm:text-xs mx-auto'>talent professionals <br /> using our platform.</p>
        </div>
        <div className="border-solid border-2 rounded-[0.6rem] border-l-[#43D1A8] border-t-[#43D1A8] border-r-[#329DA6] border-b-[#329DA6] w-[221px] h-[125px]  sm:w-56 sm:h-32 sm:mb-4 p-4 mb-[15px] text-center">
          <p className='text-[9px] sm:text-xs'>Discover</p>
          <h1 className='text-4xl text-black font-extrabold -tracking-[0.877px]'>1,300+</h1>
          <p className='text-[9px] sm:text-xs mx-auto'>talent solutions, peer-reviewed <br /> and listed on our Marketplace.</p>
        </div>
        <div className="border-solid border-2 rounded-[0.6rem] border-l-[#43D1A8] border-t-[#43D1A8] border-r-[#329DA6] border-b-[#329DA6] w-[221px] h-[125px] sm:w-56 sm:h-32 sm:mb-4  p-4 text-center">
          <p className='text-[9px] sm:text-xs'>Explore</p>
          <h1 className='text-4xl text-black font-extrabold -tracking-[0.877px]'>500+</h1>
          <p className='text-[9px] sm:text-xs mx-auto'>industry conferences <br /> and events.</p>
        </div>
      </div>
    </>
  )
}

export default IntroSection;
