import React from 'react'

const PrivacyPreferences = ({ className }) => {

  const handlePrivacyPreferences = () => {
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
  }

  return (
    <p
      onClick={handlePrivacyPreferences}
      className={className}
    >
      Privacy Preferences
    </p>
  )
}

export default PrivacyPreferences
