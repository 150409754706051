import React from 'react'
import BetaPartners from "../../../../assets/images/Partner_logo_ticker.gif"

const BetapartnersSection = () => {
  return (
    <div className='w-full max-w-[1409px] sm:w-auto max-w-[1900px] sm:mx-auto 2xl:px-40 xl:px-10 md:px-6 px-3  sm:pb-[4rem]' style={{ background: 'linear-gradient(180deg, #FFF 0%, rgba(208, 208, 208, 0.23) 86.22%, rgba(189, 189, 189, 0.12) 98.4%)' }}>
      <h3 className='text-[9px] mt-5 lg:mt-0 lg:text-[23px] lg:py-14 text-center font-semibold md:text-lg' style={{ color: 'rgba(0, 0, 0, 0.28)' }}>Our Partners</h3>
      <img src={BetaPartners} alt='beta partners image' className='object-cover w-[430px] h-[35px] md:w-[86.48%] lg:w-[1409px] lg:h-[85px] sm:w-[75%] mx-auto mt-5 pb-4 lg:mt-0' />
    </div>
  )
}

export default BetapartnersSection
