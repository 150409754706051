import React from 'react'

type MarketplaceCardProps = {
  countFigure: string;
  Description: string;
}

const MarketplaceCard = ({ countFigure, Description } : MarketplaceCardProps) => {
  return (
    <div className='flex flex-col justify-center items-center lg:w-[185px] h-[88px] bg-white rounded-xl border border-blue-tint-2 p-5'>
      <p className='text-xl font-bold text-dark-blue'>{countFigure}</p>
      <p className='text-xs text-center text-dark-blue'>{Description}</p>
    </div>
  )
}

export default MarketplaceCard
